import { Autocomplete } from '@Wonder-Cave/ui';
import { clientTcrEntityTypeDisplays } from '@shared/models/domain/clients/organization-type.enum';
import { sortByLabel } from '../../../providers/utility.provider';
import { IDropdownValue } from '../../shared/Form/Dropdown';


interface ITcrEntityTypeDropdownProps {
  value: string;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const TcrEntityTypeDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: ITcrEntityTypeDropdownProps): JSX.Element => {
  const entityTypes = Array.from(clientTcrEntityTypeDisplays.entries()).map(([label, value]) => ({
    label: value,
    value: label,
    additionalData: label,
  })) as IDropdownValue[];

  return (
    <Autocomplete
      label="What is the legal form of the organization?"
      value={entityTypes.find((o) => o.value === value)}
      placeholder='Select organization type'
      options={sortByLabel(entityTypes)}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      full
    />
  );
};

export default TcrEntityTypeDropdown;
