import { IHttpSearchResponse, ISearchLocationsRecord, ISearchLocationsRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useLocations(request: ISearchLocationsRequest): UseAxiosResult<IHttpSearchResponse<ISearchLocationsRecord>> {
  return useAxios<IHttpSearchResponse<ISearchLocationsRecord>>({
    url: 'locations/search',
    method: 'POST',
    data: request
  }, {
    manual: true
  });
};