import { HttpRequestSchema } from '@shared/services';
import { object, string } from 'yup';

export interface IMpsConfigGetOneRequest {
  id: string;
}

export const IMpsConfigGetOneRequestSchema = object<HttpRequestSchema<IMpsConfigGetOneRequest>>({
  id: string().required(),
});
