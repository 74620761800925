import { Button, ButtonVariantEnum, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { State } from '@shared/enums';
import { TcrEntityType } from '@shared/models';
import { isPhoneNumber, isValidEmail } from '@shared/services';
import { FastField, Formik } from 'formik';
import * as yup from 'yup';
import { sortByLabel } from '../../../providers/utility.provider';
import GenericDropdown from '../../shared/Form/Dropdowns/GenericDropdown';
import { IClientContactForm, countryOptions, usCountryOption } from '../types';

interface IClientContactProps {
  onSubmit: (data: IClientContactForm) => void;
  onBack: () => void;
  exchangeOptions: IDropdownValue[];
  verticalTypeOptions: IDropdownValue[];
  existingValues: IClientContactForm;
  tcrEntityType: TcrEntityType;
}

const ClientContactForm = ({ onSubmit, onBack, exchangeOptions, verticalTypeOptions, existingValues, tcrEntityType }: IClientContactProps) => {

  const stateOptions: IDropdownValue[] = sortByLabel(Object.entries(State).map(entry => ({
    label: entry[1],
    value: entry[0]
  })));

  function generateSchema(tcrEntityType: TcrEntityType) {
    const schema: yup.SchemaOf<IClientContactForm> = yup.object().shape({
      url: yup.string().url('Website must be a valid url').required('Required'),
      stockSymbol: tcrEntityType === TcrEntityType.PUBLIC_PROFIT ? yup.string().required('Required') : yup.string(),
      stockExchange: tcrEntityType === TcrEntityType.PUBLIC_PROFIT ? yup.string().required('Required') : yup.string(),
      verticalTypeCode: yup.string().required('Required'),
      referenceId: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      phoneNumber: yup.number().test(isPhoneNumber('phoneNumber', 'Mobile phone')).typeError('Mobile phone must be a number').required(),
      email: yup.string().required('Required').test(isValidEmail('support email', 'Email address')),
      street: yup.string().required('Required'),
      city: yup.string().required('Required'),
      zipCode: yup.number().min(10000, 'Invalid zip code').max(99999, 'Invalid zip code').required('Required').typeError('Zip code must be a number'),
      usStateCode: yup.string().required('Required'),
      countryCode: yup.object()
        .shape({
          label: yup.string().defined('Required'),
          value: yup.mixed().defined('Required'),
        }).required('Required'),
    });
    return schema;
  }

  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      validationSchema={generateSchema(tcrEntityType)}
      initialValues={existingValues ? existingValues : {
        url: '',
        stockSymbol: undefined,
        stockExchange: undefined,
        verticalTypeCode: undefined,
        referenceId: undefined,
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        email: '',
        street: '',
        city: '',
        usStateCode: undefined,
        zipCode: 0,
        countryCode: usCountryOption
      }}>
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError, isValid, dirty }) => (
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mr-8 space-y-8 contact-container">
            <h2 className="text-black basis-full">Contact Information</h2>
            <FastField
              component={TextInput}
              id="street"
              name="street"
              label="Address/Street"
              placeholder="Enter Street Address"
              value={values.street}
              error={touched.street ? errors.street : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FastField
              component={TextInput}
              id="city"
              name="city"
              label="City"
              placeholder="Enter City"
              value={values.city}
              error={touched.city ? errors.city : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/* ************************************** */}

            <GenericDropdown
              label="State"
              placeholder="Select a state"
              options={stateOptions}
              value={stateOptions.find(so => so.value === values.usStateCode)}
              onChange={(newValue) => {
                setFieldValue('usStateCode', newValue?.value);
                setFieldTouched('usStateCode');
              }}
              onBlur={() => {
                setFieldTouched('usStateCode');
              }}
              showError={!!(touched.usStateCode && errors.usStateCode)}
              errorMessage={errors?.usStateCode}
            />

            <FastField
              component={TextInput}
              id="zipCode"
              name="zipCode"
              label="Zip Code"
              placeholder="Enter ZIP Code"
              value={values.zipCode}
              error={touched.zipCode ? errors.zipCode : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* ************************************** */}

            <GenericDropdown
              label="Country"
              placeholder="Select a country"
              options={countryOptions}
              value={values.countryCode}
              onChange={(newValue) => {
                setFieldValue('countryCode', newValue);
                setFieldTouched('countryCode');
              }}
              onBlur={() => {
                setFieldTouched('countryCode');
              }}
              showError={!!(touched.countryCode && errors.countryCode)}
              errorMessage={(errors?.countryCode as any)?.value as string}
              disabled
            />

            <FastField
              component={TextInput}
              id="url"
              name="url"
              label="Website/Online Presence"
              placeholder="Enter URL"
              value={values.url}
              error={touched.url ? errors.url : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* ************************************** */}

            <FastField
              component={TextInput}
              id="stockSymbol"
              name="stockSymbol"
              label="Stock Symbol"
              value={values.stockSymbol}
              error={touched.stockSymbol ? errors.stockSymbol : ''}
              onChange={(e) => {
                setFieldValue('stockSymbol', e.target.value);
                setFieldTouched('stockSymbol');
              }}
              onBlur={() => {
                setFieldTouched('stockSymbol');
              }}
            />

            <GenericDropdown
              label="Stock Exchange"
              options={exchangeOptions}
              value={exchangeOptions.find(se => se.value === values.stockExchange)}
              onChange={(newValue) => {
                setFieldValue('stockExchange', newValue?.value);
                setFieldTouched('stockExchange');
              }}
              onBlur={() => {
                setFieldTouched('stockExchange');
              }}
              showError={!!(touched.stockExchange && errors.stockExchange)}
              errorMessage={(errors?.stockExchange as any) as string}
            />

            {/* ************************************** */}

            <GenericDropdown
              label="Vertical Type"
              options={verticalTypeOptions}
              value={verticalTypeOptions.find(vto => vto.value === values.verticalTypeCode)}
              onChange={(newValue) => {
                setFieldValue('verticalTypeCode', newValue?.value);
                setFieldTouched('verticalTypeCode');
              }}
              onBlur={() => {
                setFieldTouched('verticalTypeCode');
              }}
              showError={!!(touched.verticalTypeCode && errors.verticalTypeCode)}
              errorMessage={errors?.verticalTypeCode}
            />

            {/* ************************************** */}

            <FastField
              component={TextInput}
              id="phoneNumber"
              name="phoneNumber"
              label="Mobile Phone"
              placeholder="Enter your phone number"
              value={values.phoneNumber}
              error={touched.phoneNumber ? errors.phoneNumber : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <FastField
              component={TextInput}
              id="email"
              name="email"
              label="Email Address"
              placeholder="Enter your email"
              value={values.email}
              error={touched.email ? errors.email : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

          </div>
          <div className="flex justify-end w-full mt-8">
            <Button
              variant={ButtonVariantEnum.SECONDARY}
              className="mr-4"
              onClick={onBack}
            >
              BACK
            </Button>
            <Button formId="contact-form" type="submit" disabled={!values.street || !isValid || (tcrEntityType === TcrEntityType.PUBLIC_PROFIT && (!values.stockSymbol || !values.stockExchange))}>
              NEXT
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ClientContactForm;
