import { IHttpSearchResponse, ISearchClientsRecord, ISearchClientsRequest, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { getFilterValue } from '../authAxios';

export default function useClients(searchRequest: ISearchRequest): UseAxiosResult<IHttpSearchResponse<ISearchClientsRecord>> {
  const request: ISearchClientsRequest = {
    name: getFilterValue(searchRequest, 'name'),
    isActive: getFilterValue(searchRequest, 'isActive'),
    providerName: getFilterValue(searchRequest, 'providerName'),
    authId: getFilterValue(searchRequest, 'authId'),
    environment: getFilterValue(searchRequest, 'environmentId'),
    skip: searchRequest?.pagination?.skip ?? 0,
    take: searchRequest?.pagination?.take ?? 10,
    sortFieldName: searchRequest?.sort?.fieldName,
    sortDirection: searchRequest?.sort?.sortDirection,
  };

  return useAxios<IHttpSearchResponse<ISearchClientsRecord>>({
    url: 'clients',
    method: 'GET',
    params: request,
  });
}