import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Role } from '@shared/models';
import { Outlet } from 'react-router-dom';
import Roles from '../Roles';

export interface ProtectedRouteProps {
  roles?: Auth0Role[];
  [x: string]: any;
}

const ProtectedRoute = ({ roles }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  return ((isAuthenticated && roles) ?
    <Roles roles={roles}>
      <Outlet />
    </Roles>

    : <Outlet />
  );
};

export { ProtectedRoute };

