import { groupBy, sum } from 'lodash';
import { useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import { Button } from '../shared/Buttons/Button';
import TableList from '../shared/Table/OldTableList';
import { getEditorColumns } from './types';

interface FormattedConfig {
  environmentName: string,
  providerId: string,
  smsMPS: number,
  mmsMPS: Number;
}

interface FormattedTotal {
  providerId: string,
  smsMPS: number,
  mmsMPS: Number;
}

interface FormattedValues {
  configs: FormattedConfig[],
  totals: FormattedTotal[];
}


const MPSEditor = () => {
  const [tableData, setTableData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();


  const getCapacity = async () => {
    const response = await axiosPost('/mps-editor-get-capacity');
    const data = response.data;
    const td = data?.providers?.length > 0 ? data?.providers?.map(td => {
      const environments = td.environments;
      return { ...td, ...environments };
    }) : [];
    setTableData({ headers: data.headers, data: td });
  };

  const updateCapacity = async () => {
    setLoading(true);
    try {
      const providers = groupBy(tableData?.data, 'id');
      const request = Object.keys(providers).reduce((formattedValues: FormattedValues, providerId) => {
        const sms = providers[providerId].find(p => p.name.includes('SMS'));
        const mms = providers[providerId].find(p => p.name.includes('MMS'));
        tableData.headers.forEach(environment => {
          formattedValues.configs.push({
            environmentName: environment,
            providerId: providerId,
            smsMPS: Number(sms[environment]),
            mmsMPS: Number(mms[environment])
          });
        });
        formattedValues.totals.push({
          providerId: providerId,
          smsMPS: Number(sms.providerTotal),
          mmsMPS: Number(mms.providerTotal)
        });
        return formattedValues;
      }, { configs: [], totals: [] });
      await axiosPost('/mps-editor-update-capacity', request);
      addNotification({ content: 'Successfully updated capacity' });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCapacity();
  }, []);

  const validateRow = (item, index, setRowEditable) => {
    const { environments, id, name, valid, providerTotal, ...values } = item;
    const _sum = sum(Object.values(values).map(v => Number(v)));
    if (_sum > providerTotal) {
      setTableData(prevState => {
        const data = [...prevState?.data ?? []];
        const row = data[index];
        row.valid = 1;
        data[index] = row;
        return { ...prevState, data: data };
      });
    } else if (_sum < providerTotal) {
      setTableData(prevState => {
        const data = [...prevState?.data ?? []];
        const row = data[index];
        row.valid = -1;
        data[index] = row;
        return { ...prevState, data: data };
      });
    } else {
      setTableData(prevState => {
        const data = [...prevState?.data ?? []];
        const row = data[index];
        row.valid = 0;
        data[index] = row;
        return { ...prevState, data: data };
      });
      setRowEditable(undefined);
    }
  };
  return <><div className='space-y-4'>
    <div className='flex items-center justify-between px-28'>
      <h1 className='inline-block'>MPS Editor</h1>
      <Button disabled={(tableData?.data?.map(row => row.valid ?? 0).some((valid) => valid !== 0)) || loading} isLoading={loading} onClick={updateCapacity}>SAVE & EXECUTE</Button>
    </div>

    <div className='pb-5 px-28'>
      <TableList
        columns={getEditorColumns(tableData?.headers ?? [])}
        items={tableData?.data ?? []}
        onCellEdit={(e, index, column) => setTableData!(prevState => {
          const table = [...prevState.data];
          const row = table[index];
          row[column.fieldName] = e.target.value;
          table[index] = row;
          return {
            headers: [...prevState.headers],
            data: table
          };
        })}
        validateRow={validateRow}
      />
    </div>
  </div>
  </>;
};

export default MPSEditor;