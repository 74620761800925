import { IHttpSearchResponse, ISearchRequest, ISearchTcrCampaignsRecord, ISearchTcrCampaignsRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { getFilterValue } from '../authAxios';

export interface UseTcrCampaignsRequest {
  searchRequest: ISearchRequest;
  clientId?: string;
}

export default function useTcrCampaigns({ searchRequest, clientId }: UseTcrCampaignsRequest): UseAxiosResult<IHttpSearchResponse<ISearchTcrCampaignsRecord>> {
  const request: ISearchTcrCampaignsRequest = {
    isActive: getFilterValue(searchRequest, 'isActive'),
    id: getFilterValue(searchRequest, 'id'),
    tcrCampaignId: getFilterValue(searchRequest, 'tcrCampaignId'),
    description: getFilterValue(searchRequest, 'description'),
    providerProfileId: getFilterValue(searchRequest, 'providerProfileId'),
    providerId: getFilterValue(searchRequest, 'providerId'),
    clientId: clientId ?? getFilterValue(searchRequest, 'clientId'),
    messageType: getFilterValue(searchRequest, 'messageType'),
    createdAt: getFilterValue(searchRequest, 'createdAt'),
    updatedAt: getFilterValue(searchRequest, 'updatedAt'),
    createdBy: getFilterValue(searchRequest, 'createdBy'),
    updatedBy: getFilterValue(searchRequest, 'updatedBy'),
    campaignType: getFilterValue(searchRequest, 'campaignType'),
    tenantId: getFilterValue(searchRequest, 'tenantId'),

    skip: searchRequest?.pagination?.skip ?? 0,
    take: searchRequest?.pagination?.take ?? 10,
    sortFieldName: searchRequest?.sort?.fieldName,
    sortDirection: searchRequest?.sort?.sortDirection,
  };

  return useAxios<IHttpSearchResponse<ISearchTcrCampaignsRecord>>({
    url: 'tcr-campaigns',
    method: 'GET',
    params: request,
  });
}