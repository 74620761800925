import { HttpRequestSchema } from '@shared/services';
import { object, string } from 'yup';

export interface IEnvironmentGetOneRequest {
  id: string;
}

export const IEnvironmentGetOneRequestSchema = object<HttpRequestSchema<IEnvironmentGetOneRequest>>({
  id: string().required(),
});
