interface ILoadingIndicatorProps {
  position?: 'LEFT' | 'CENTER' | 'RIGHT';
  className?: string;
  size?: number;
  vPosition?: 'CENTER';
  dropdown?: boolean;
}

const LoadingIndicator = ({
  position = 'LEFT',
  className,
  size,
  vPosition,
  dropdown,
}: ILoadingIndicatorProps): JSX.Element => {
  return (
    <div
      className={`flex ${position === 'LEFT' ? '' : position === 'CENTER' ? 'justify-center' : 'justify-right'} ${
        vPosition === 'CENTER' ? 'items-center h-screen' : ''
      } ${dropdown ? 'items-center' : ''}`}
    >
      <div
        className={`${
          !!size ? `h-${size} w-${size}` : 'w-5 h-5'
        } ease-linear border-2 border-t-2 border-gray-200 dark:border-t-slate-700 dark:border-slate-400 rounded-full border-t-sky-500 loader ${className}`}
      ></div>
    </div>
  );
};

export default LoadingIndicator;
