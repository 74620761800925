import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { GREnvironment } from '@shared/models';
import useClickerGroups from 'apps/gsd-portal/src/hooks/useClickerGroups';
import { useEffect } from 'react';
import { sortByLabel } from '../../../providers/utility.provider';

interface IClickerGroupDropdownProps {
  value?: string | null;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  orgId: string;
  environment: GREnvironment;
  defaultGroupName?: string;
  includeNone?: boolean;
}

const ClickerGroupDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  orgId,
  environment,
  defaultGroupName,
  includeNone,
}: IClickerGroupDropdownProps): JSX.Element => {
  const [{ data: clickerGroupData, loading: clickerGroupLoading, error: clickerGroupError }, getClickerGroups] = useClickerGroups({ orgId, environment });

  useEffect(() => {
    if (orgId) {
      getClickerGroups();
    }
  }, [orgId]);

  const clickerGroups: IDropdownValue[] = sortByLabel(
    clickerGroupData?.records?.map((cg) => {
      return {
        label: cg.name ?? '',
        value: cg.id ?? '',
      };
    }) ?? []
  );

  if (includeNone) {
    clickerGroups.push({ label: 'None', value: undefined });
  }

  const getValue = () => {
    return clickerGroups?.find((cg) => cg.value === value)
      ?? clickerGroups?.find((cg) => cg.label === defaultGroupName);
  };

  return (
    <Autocomplete
      label="Default Clicker Group"
      value={getValue()}
      options={clickerGroups}
      onChange={onChange}
      onBlur={onBlur}
      loading={clickerGroupLoading}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default ClickerGroupDropdown;
