import { TcrCampaignStage } from '@shared/enums';
import { CampaignTypeEnum } from '@shared/enums/campaign-type-enum';
import { TcrRegistryAppeal, TcrRegistryBrand } from '@shared/models';
import { TcrRegistryCampaign } from '../clients/tcr-campaign.interface';
import { TcrRegistryVetting } from '../clients/tcr-vetting.interface';
import { GRClickerGroup, GRClient, GRLocation, GRProvider, GRTcrCampaign, GRTenant } from './grassroots.entities';

export enum GREnvironment {
  DEV = 'dev',
  DEV2 = 'dev2',
  STAGING = 'staging',
  STAGING2 = 'staging2',
  PROD = 'prod',
  TAG = 'tag',
  SHARED = 'shared',
  DONOR_BUREAU = 'donorb'
}

export interface GRCampaignTypeProvider {
  id: string;
  name: string;
  campaignType: CampaignTypeEnum;
}

export interface GSDTcrCampaign {
  id: string;
  tcrCampaignId: string;
  isActive: boolean;
  campaignType: CampaignTypeEnum;
  provider: { id: string, name: string; };
  system_numbers_present: boolean;
}

export interface ISearchClientsRecord extends GRClient {
  environment: GREnvironment;
  providers?: GRCampaignTypeProvider[];
  tenant?: Pick<GRTenant, 'id' | 'name' | 'authId'>;
  tcr_campaigns?: GSDTcrCampaign[];
  defaultClickerGroup?: Pick<GRClickerGroup, 'id' | 'name'>;
  tcrStage: TcrCampaignStage;
  tcrBrand?: TcrRegistryBrand;
  tcrAppeals?: TcrRegistryAppeal[];
  tcrVetting?: TcrRegistryVetting[];
  providerName?: string;
}

export interface ISearchTcrCampaignsRecord extends GRTcrCampaign {
  environment: GREnvironment;
  provider?: Omit<GRProvider, 'config'>;
  phoneNumberCount?: number;
  stage?: TcrCampaignStage;
  tcrCampaign?: TcrRegistryCampaign;
  tcrBrand?: TcrRegistryBrand;
}

export interface IUpdateClientResponse extends GRClient {
  environment: GREnvironment;
}

export interface IUpdateTcrCampaignResponse extends GRTcrCampaign {
  environment: GREnvironment;
}

export interface ISearchLocationsRequest {
  environment: GREnvironment;
  city?: string;
  stateCode?: string;
  stateName?: string;
  zipCodes?: string;
  limit: number;
}

export interface ISearchLocationsRecord extends GRLocation {
  environment: GREnvironment;
}

export interface ISearchSystemNumbersRequest {
  environment: GREnvironment;
  tcrCampaignId: string;
  limit: number;
  city?: string;
  state?: string;
  areaCode?: string;
}

export interface ISearchSystemNumbersResponse {
  numbers: string[];
}

export interface IPurchaseSystemNumbersRequest {
  environment: GREnvironment;
  tcrCampaignId: string;
  phoneNumbers: string[];
}

export interface ISearchLocalSystemNumbersRequest {
  environment: GREnvironment;
  tcrCampaignId: string;
  isDeleted?: boolean;
  isActive?: boolean;
}

export interface IDeactivateSystemNumbersRequest {
  environment: GREnvironment;
  tcrCampaignId: string;
  phoneNumbers: string[];
}