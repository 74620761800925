import { SystemNumberStatusEnum } from '@shared/enums/system-number-status-enum';
import { ISearchClientsRecord, ISearchTcrCampaignsRecord, IUpdateTcrCampaignRequest, IUpdateTcrCampaignResponse } from '@shared/models';
import { TcrRegistryCampaignStatus } from '@shared/models/domain/clients/tcr-campaign.interface';
import { deFormatPhone, extendObject } from '@shared/services';
import { secondsToMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPut } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useLocalSystemNumbers from '../../hooks/useLocalSystemNumbers';
import DeactivateNumbers from '../Numbers/DeactivateNumbers';
import PurchaseNumbers from '../Numbers/PurchaseNumbers';
import TCRCampaignDetailsHeader from './TCRCampaignDetailsHeader';
import TCRCampaignDetailsMain from './TCRCampaignDetailsMain';
import TCRCampaignToggleModal from './TCRCampaignToggleModal';

export interface TCRCampaignDetailsProps {
  isReadOnly?: boolean;
}

const TCRCampaignDetails = ({
  isReadOnly
}: TCRCampaignDetailsProps) => {
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const { clientId, tcrId } = useParams();
  const [client, setClient] = useState<ISearchClientsRecord>();
  const [tcrCampaign, setTcrCampaign] = useState<ISearchTcrCampaignsRecord>();
  const [isClientLoading, setIsClientLoading] = useState<boolean>(false);
  const [isTCRCampaignLoading, setIsTCRCampaignLoading] = useState<boolean>(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false);
  const [isActiveSystemNumbersLoading, setIsActiveSystemNumbersLoading] = useState<boolean>(true);
  const [activeSystemNumbers, setActiveSystemNumbers] = useState<{ number: string; status: SystemNumberStatusEnum; }[] | undefined>([]); // todo 3418: implement active numbers to include status for label
  const [showTcrCampaignToggleModal, setShowTcrCampaignToggleModal] = useState(false);

  const isLoading = isClientLoading || isTCRCampaignLoading;
  const disabled = isReadOnly || isLoading || !tcrCampaign?.isActive;

  const [{ data: localSystemNumbersData, loading: localSystemNumbersLoading }, searchLocalSystemNumbers] = useLocalSystemNumbers({
    environment: tcrCampaign?.environment!,
    tcrCampaignId: tcrCampaign?.id!,
    isActive: true,
    isDeleted: false
  });

  const getClient = async () => {
    try {
      setIsClientLoading(true);
      return await axiosGet<ISearchClientsRecord>(`/clients/${clientId}`);
    } catch (error) {
      setClient(undefined);
      console.error('Search Client Error', error);
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to load the Client.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsClientLoading(false);
    }
  };

  const getTCRCampaign = async () => {
    try {
      setIsTCRCampaignLoading(true);
      return await axiosGet<ISearchTcrCampaignsRecord>(`/tcr-campaigns/${tcrId}`);
    } catch (error) {
      setTcrCampaign(undefined);
      console.error('Search TCR Error', error);
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to load the TCR Campaign.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsTCRCampaignLoading(false);
    }
  };

  const toggleStatus = async () => {
    if (!tcrCampaign) {
      return;
    }

    setIsLoadingStatus(true);

    try {
      const isActive = !tcrCampaign.isActive;

      const request: IUpdateTcrCampaignRequest = {
        environment: tcrCampaign.environment,
        id: tcrCampaign.id,
        isActive,
        isFullDisable: !isActive // disable real tcr if tcr campaign is disabled on details page
      };

      await axiosPut<IUpdateTcrCampaignResponse>(`/tcr-campaigns/${tcrCampaign.id}`, request);

      setTcrCampaign(extendObject(tcrCampaign, {
        isActive,
        tcrCampaign: extendObject(tcrCampaign?.tcrCampaign, {
          status: request.isFullDisable
            ? TcrRegistryCampaignStatus.EXPIRED
            : tcrCampaign?.tcrCampaign?.status
        })
      }));
    } catch (error) {
      console.error('Error updating status:', error);

      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to disable the TCR Campaign.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const refetchClient = async () => {
    const client = await getClient();
    setClient(client?.data);
  };

  const refetchTcrCampaign = async () => {
    const tcrCampaign = await getTCRCampaign();
    setTcrCampaign(tcrCampaign?.data);
  };

  const handleDeactivate = async (numbers: string[]) => {
    navigate(`/clients/${clientId}/tcr`);
  };

  const handlePurchase = async (numbers: string[]) => {
    navigate(`/clients/${clientId}/tcr`);
  };

  const searchActivePhoneNumbers = async (tcrCampaign?: ISearchTcrCampaignsRecord) => {
    try {
      if (tcrCampaign) {
        await searchLocalSystemNumbers({
          data: {
            environment: tcrCampaign?.environment!,
            tcrCampaignId: tcrCampaign?.id!,
            isActive: true,
            isDeleted: false
          }
        });
      }
    } catch (error: any) {
      console.error(error);

      if (error?.constructor?.name === 'Cancel') {
        return;
      }

      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to load Active Numbers.',
        type: NotificationType.FAILURE,
      });
    } finally {
      if (tcrCampaign) {
        setIsActiveSystemNumbersLoading(false);
      }
    }
  };

  useEffect(() => {
    searchActivePhoneNumbers(tcrCampaign);
  }, [tcrCampaign]);

  useEffect(() => {
    // poll active numbers
    const seconds = 10;
    const interval = setInterval(() => searchActivePhoneNumbers(tcrCampaign), secondsToMilliseconds(seconds));
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    refetchClient();
    refetchTcrCampaign();
  }, [clientId, tcrId]);

  useEffect(() => {
    const phoneNumbers = localSystemNumbersData?.records?.map((record) => {
      return {
        number: deFormatPhone(record?.phoneNumber?.toString()) as string,
        status: record.status
      };

    }) ?? []; // todo 3418: include status for label
    setActiveSystemNumbers(phoneNumbers ?? []);
  }, [localSystemNumbersData]);

  return <div className='flex flex-col pt-6 pb-8 pl-24 pr-24'>
    <TCRCampaignDetailsHeader
      isReadOnly={disabled}
      client={client}
      tcrCampaign={tcrCampaign}
      onStatusToggle={() => setShowTcrCampaignToggleModal(true)}
      isLoading={isLoading}
      isLoadingStatus={isLoadingStatus}
    />

    <TCRCampaignDetailsMain
      isReadOnly={disabled}
      client={client}
      tcrCampaign={tcrCampaign}
      isLoading={isLoading}
    />

    <div className="w-full mt-4 mb-8 border-2 border-t border-mystic"></div>

    <div className='flex flex-row justify-between'>
      <PurchaseNumbers
        isReadOnly={disabled}
        client={client}
        tcrCampaign={tcrCampaign}
        isLoading={isLoading}
        isActiveSystemNumbersLoading={isActiveSystemNumbersLoading}
        activeSystemNumbers={activeSystemNumbers}
        onPurchase={handlePurchase}
      />

      <DeactivateNumbers
        isReadOnly={disabled}
        client={client}
        tcrCampaign={tcrCampaign}
        isLoading={isLoading}
        isActiveSystemNumbersLoading={isActiveSystemNumbersLoading}
        activeSystemNumbers={activeSystemNumbers}
        onDeactivate={handleDeactivate}
      />
    </div>

    <TCRCampaignToggleModal
      show={showTcrCampaignToggleModal}
      tcrCampaign={tcrCampaign}
      onStatusToggle={() => {
        setShowTcrCampaignToggleModal(false);
        toggleStatus();
      }}
      onClose={() => setShowTcrCampaignToggleModal(false)}
    />
  </div>;
};

export default TCRCampaignDetails;
