import { IColumn, IColumnClickConfig, IColumnRenderConfig } from '@Wonder-Cave/ui';
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import { IDropdownValue } from '../shared/Form/Dropdown';

export interface IClientFormProvider {
  id: string | undefined;
  campaignType: string | undefined;
  provider: string | undefined;
}

export interface ICompanyInfoForm {
  companyName: string;
  tenantId: string | undefined;
  orgId: string | undefined;
  defaultClickerGroupId?: string | undefined;
  brandName: string;
  clientName: string;
  isActive: boolean;
  externalId?: string;
  senderIdentifier?: string;
  messageFlow?: string;
  twoWayEnabled: boolean;
  tcrEntityType?: string;
  registrationCountryCode: IDropdownValue;
  taxId: string;
  taxIdCountryCode: IDropdownValue;
  altBusinessIdType?: string;
  altBusinessId?: string;
  tcrBrandId?: string;
  areaCode?: string;
}

export interface IClientContactForm {
  url: string;
  stockSymbol?: string;
  stockExchange?: string;
  verticalTypeCode?: string;
  referenceId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: number;
  email: string;

  street: string;
  city: string;
  usStateCode?: string;
  zipCode: number | undefined;
  countryCode: IDropdownValue;
}

export interface IClientEditForm {
  clientName: string;
  tenantId: string;
  defaultClickerGroupId?: string | null;
  senderIdentifier?: string;
  messageFlow?: string;
  externalId?: string;
  providerId?: string | null | undefined;
  selectProviderByCampaignType: boolean;
  fundraisingProviderId?: string;
  gotvProviderId?: string;
  persuasionProviderId?: string;
  listBuildingProviderId?: string;
}

export interface ITokenForm {
  partnerCode?: IDropdownValue;
  partnerId?: string;
  partnerToken?: string;
}

export const clientTableTake = 10;

export const defaultClientTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: clientTableTake,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export function getColumns(clickEvents: IColumnClickConfig, renders: IColumnRenderConfig): IColumn[] {
  return [
    {
      headerName: 'Client',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: clickEvents['name'],
      renderColumn: renders['name']
    },
    {
      headerName: 'TCR',
      fieldName: 'tcrStage',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: renders['tcrStage']
    },
    {
      headerName: 'Status',
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
      renderColumn: renders['isActive']
    },
    {
      headerName: 'Provider',
      fieldName: 'providers',
      fieldType: FilterDataTypeEnum.STRING,
      emptyValue: 'N/A',
      renderColumn: renders['providers']
    },
    {
      headerName: 'Provider Updated',
      fieldName: 'providerUpdatedAt',
      fieldType: FilterDataTypeEnum.DATE,
      emptyValue: 'N/A'
    },
    {
      headerName: 'Date Added',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
    {
      headerName: 'Actions',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS,
    }
  ];
};

export const usCountryOption: IDropdownValue = { value: 'US', label: 'United States of America' };
export const countryOptions: IDropdownValue[] = [usCountryOption];
