import { useAuth0 } from '@auth0/auth0-react';
import { Auth0UserMembership, IHttpResponse } from '@shared/models';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { axiosPost } from './authAxios';
import NotificationsRegion from './components/NotificationsRegion';
import LoadingIndicator from './components/shared/LoadingIndicator';
import { LeftNav } from './components/shared/Nav/LeftNav';
import { MembershipsContextProvider } from './contexts/MembershipContext';
import { NotificationsProvider } from './contexts/NotificationContext';
import { SidebarContextProvider } from './contexts/SidebarContext';
import { SocketContextProvider, socketManager } from './contexts/SocketContext';
import { logout } from './providers/auth0.service';

interface IAppProps {
  configured: boolean;
  children?: JSX.Element;
}

const AppWrapper = ({ configured, children }: IAppProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);
  const [activeUser, setActiveUser] = useState({});
  const [devMode, setDevMode] = useState(false);
  const [membership, setMembership] = useState<Auth0UserMembership>();
  const sidebarContextValue = { devMode, sidebarOpen, activeUser, setDevMode, setSidebarOpen, setActiveUser };
  const { isLoading, isAuthenticated: isLoggedIn, logout: auth0Logout, user: auth0User } = useAuth0();
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const membershipsContextValue = { membership, isLoaded: permissionsLoaded, setMembership };

  const theme = localStorage.getItem('gsd-theme');
  if (theme === 'dark') {
    // This enables dark mode if the user has it set as their OS default || (!theme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }

  useEffect(() => {
    if (configured && isLoggedIn) {
      const setMembershipContext = async () => {
        try {
          const response = await axiosPost<IHttpResponse<Auth0UserMembership>>('/auth0-get-my-membership');
          if (response.data) {
            setMembership(response.data);
            setPermissionsLoaded(true);
          }
        } catch (e) {
          console.error(e);
          logout({ auth0Logout, auth0User });
        }
      };
      setMembershipContext();
    }
  }, [configured]);

  if (!isLoading && !isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (!configured || isLoading) {
    console.log('Loading...');
    console.log(`Configured: ${configured}`);
    console.log(`Auth0 Loading: ${isLoading}`);
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }
  return (
    <MembershipsContextProvider value={membershipsContextValue}>
      <SocketContextProvider value={socketManager}>
        <SidebarContextProvider value={sidebarContextValue}>
          <NotificationsProvider>
            <div
              className={`flex h-screen overflow-hidden bg-gray-100 text-black dark:bg-slate-900 dark:text-slate-400`}
            >
              <LeftNav />
              <div className="flex flex-col flex-1 w-0 overflow-hidden">
                <NotificationsRegion />
                <main className={`relative flex-1 pt-6 overflow-y-auto focus:outline-none `}>
                  <div className="h-full">
                    <Outlet />
                  </div>
                </main>
              </div>
            </div>
          </NotificationsProvider>
        </SidebarContextProvider>
      </SocketContextProvider>
    </MembershipsContextProvider>
  );
};

export default AppWrapper;
