import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';
import { ISearchTcrCampaignsRecord } from '@shared/models';

interface TCRCampaignToggleModalProps {
  show: boolean;
  tcrCampaign?: ISearchTcrCampaignsRecord;
  onClose: () => void;
  onStatusToggle: () => void;
}

const TCRCampaignToggleModal = ({ show, tcrCampaign, onClose, onStatusToggle }: TCRCampaignToggleModalProps) => {
  const titleText = 'Are you sure?';

  const messageText = !!tcrCampaign?.isActive
    ? `By clicking 'Disable', your TCR Campaign will be disabled on the TCR Portal`
    : `By clicking 'Enable', your TCR Campaign will be enabled on the TCR Portal`;

  const buttonText = !!tcrCampaign?.isActive
    ? 'DISABLE'
    : 'ENABLE';

  const titleComponent = <div className='flex flex-col items-center mt-4 mb-4 text-center'>
    <h2 className='w-full pb-4'>{titleText}</h2>
    <div className='text-regular text-dark-gray max-w-[431px]'>{messageText}</div>
  </div>;

  return (
    <Modal
      title={titleComponent}
      show={show}
      onClose={() => {
        onClose();
      }}
      titleClassName='pl-8 text-black'
      childrenClassName='pl-8 pb-2 pt-2'
    >
      <div className="flex flex-row flex-wrap justify-center pb-6">
        <Button variant={ButtonVariantEnum.SECONDARY} className="mb-2 mr-4" onClick={() => onClose()}>CANCEL</Button>
        <Button variant={ButtonVariantEnum.PRIMARY} className="mb-2 mr-4" onClick={() => onStatusToggle()}>{buttonText}</Button>
      </div>
    </Modal>
  );
};

export default TCRCampaignToggleModal;
