import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';

interface ISystemNumbersDropdownProps {
  value: IDropdownValue[] | undefined;
  options: IDropdownValue[];
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  tcrCampaignId?: string;
  label?: string;
  errorLocation?: 'BOTTOM';
  optionLabelClassName?: string;
}

const SystemNumbersDropdown = ({
  value,
  options,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  label,
  errorLocation,
  optionLabelClassName,
}: ISystemNumbersDropdownProps): JSX.Element => {
  return (
    <div className={`${options?.length !== value?.length ? '[&_ul]:min-h-min max-h-48' : '[&_ul]:hidden'}`}>
      <Autocomplete
        label={label}
        placeholder="Search for Numbers"
        value={value}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        error={errorMessage}
        disabled={disabled}
        multiple
        showError={showError}
        top
        errorLocation={errorLocation}
        optionLabelClassName={optionLabelClassName}
      />
    </div>
  );
};

export default SystemNumbersDropdown;
