import { Auth0Role } from "@shared/models";
import { checkAuth0Roles } from '@shared/services';
import { useMembership } from '../contexts/MembershipContext';

const useRoles = (targetRoles: Auth0Role[]) => {
  const userRoles = useMembership()?.membership?.roles ?? [];
  return checkAuth0Roles({ userRoles, targetRoles });
};

export default useRoles;
