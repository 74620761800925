import { isArray as _isArray, assign, chunk, cloneDeep, drop, isEmpty, orderBy, set, take } from 'lodash';
import util from 'util';
import { v4 } from 'uuid';

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getSMSMessageSegmentCount(message: string): number {
  let messageLength: number = getSMSMessageLength(message);
  let segmentLength: number = 160; // GSM-7 Segment length
  let segmentHeaderLength: number = 7; // GSM-7 Segment Data Header

  if (!isGsmMessage(message)) {
    // UCS-2 Segment length and compensation
    segmentLength = 70;
    segmentHeaderLength = 3;
  }

  const effectiveSegment = segmentLength - segmentHeaderLength;

  // The first two segments are calculated differently (first slightly larger, second slightly smaller). All others are equal
  if (messageLength <= segmentLength * 2) {
    return messageLength <= segmentLength ? 1 : 2;
  }

  messageLength -= effectiveSegment * 2;

  const segments = Math.ceil(messageLength / effectiveSegment) + 2; // Add in the two irregular segments

  return segments;
}

export function getSMSMessageSegmentCountWithLink(message: string): number {
  let messageLength: number = getSMSMessageLengthWithLink(message);
  let segmentLength: number = 160; // GSM-7 Segment length
  let segmentHeaderLength: number = 7; // GSM-7 Segment Data Header

  if (!isGsmMessage(message)) {
    // UCS-2 Segment length and compensation
    segmentLength = 70;
    segmentHeaderLength = 3;
  }

  const effectiveSegment = segmentLength - segmentHeaderLength;

  // The first two segments are calculated differently (first slightly larger, second slightly smaller). All others are equal
  if (messageLength <= segmentLength * 2) {
    return messageLength <= segmentLength ? 1 : 2;
  }

  messageLength -= effectiveSegment * 2;

  const segments = Math.ceil(messageLength / effectiveSegment) + 2; // Add in the two irregular segments

  return segments;
}

export function isGsmMessage(message: string): boolean {
  const gsmCodePoints = new Set([
    0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a,
    0x002b, 0x002c, 0x002d, 0x002e, 0x002f, 0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038,
    0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046,
    0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d, 0x004e, 0x004f, 0x0050, 0x0051, 0x0052, 0x0053, 0x0054,
    0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063,
    0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f, 0x0070, 0x0071,
    0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1,
    0x00a3, 0x00a4, 0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9, 0x00d1, 0x00d6, 0x00d8, 0x00dc,
    0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec, 0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
    0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9, 0x20ac,
  ]);

  for (const s of message) {
    const codePoint = s.codePointAt(0);
    if (codePoint && !gsmCodePoints.has(codePoint)) {
      return false;
    }
  }
  return true;
}

export function getSMSMessageLength(message: string): number {
  const length: number = message.length;

  // Account for escaped characters
  const escapedCharacters: Set<number> = new Set([
    0x007c, 0x005e, 0x20ac, 0x007b, 0x007d, 0x005b, 0x005d, 0x007e, 0x005c,
  ]);

  const escapedCharacterCount: number = message
    .split('')
    .filter((char) => escapedCharacters.has(char.codePointAt(0) ?? -1)).length;

  // It seems that different providers handle newlines differently. If we need to start calculating for them, add in this logic:
  // const lines = (message.match(/\n/g) || '').length;

  return length + escapedCharacterCount;
}

export function getSMSMessageLengthWithLink(message: string): number {
  const linkCount = message.match(/{{link}}/g)?.length ?? 0;

  const length: number = message.length;

  // Account for escaped characters
  const escapedCharacters: Set<number> = new Set([
    0x007c, 0x005e, 0x20ac, 0x007b, 0x007d, 0x005b, 0x005d, 0x007e, 0x005c,
  ]);

  const escapedCharacterCount: number = message
    .split('')
    .filter((char) => escapedCharacters.has(char.codePointAt(0) ?? -1)).length;

  // It seems that different providers handle newlines differently. If we need to start calculating for them, add in this logic:
  // const lines = (message.match(/\n/g) || '').length;

  return length + escapedCharacterCount + (linkCount * 5);
}

export const uuid = v4;

export function generateGUID(): string {
  return uuid();
}

export function stringToBase64(str: string): string {
  return Buffer.from(str, 'utf-8').toString('base64');
}

export function isDummyNumber(phoneNumber: number | string) {
  return extractPhoneNumberString(phoneNumber)?.substring(3, 6) === '555';
}

export function extractPhoneNumberString(value: number | string) {
  const phone = extractPhoneNumber(value);
  return phone ? String(phone) : null;
}

export function extractPhoneNumbers(values: (number | string)[]) {
  return values
    ?.map(value => extractPhoneNumber(value))
    ?.filter(value => !isNil(value))
    ?? [];
}

export function extractPhoneNumberStrings(values: (number | string)[]) {
  return values
    ?.map(value => extractPhoneNumberString(value))
    ?.filter(value => !isNil(value))
    ?? [];
}

export function extractPhoneNumber(value: number | string) {
  try {
    if (isNil(value)) {
      return null;
    }

    const str = typeof value === 'string' ? value : String(value);

    let result = str.replace(/\D/g, '').trim();

    if (result?.length < 10) {
      return null;
    }

    if (result?.charAt(0) === '1') {
      result = result?.substring(1);
    }

    if (result?.length !== 10) {
      return null;
    }

    return parseInt(result);
  } catch (error) {
    return null;
  }
}

export function deFormatPhone(phoneNumber: string): string | null {
  // Remove all parenthesis, dashes, plus, dots and spaces
  let deFormattedNumber = phoneNumber?.replace(/[()\-+\s\.]/g, '').trim();

  if (!deFormattedNumber) {
    console.log(`bad number: "${phoneNumber}"`);
    return null;
  }

  // Remove leading 1. US Area codes cannot start with 1, so leading 1's mean the country code was included
  if (deFormattedNumber.charAt(0) === '1') {
    deFormattedNumber = deFormattedNumber.substring(1);
  }

  // If they pass in anything other than a 10-digit number, skip
  if (!/^\d{10}$/g.test(deFormattedNumber)) {
    console.log('bad number:', phoneNumber);
    return null;
  }

  if (deFormattedNumber?.charAt(0) === '0') {
    console.log('bad number:', phoneNumber);
    return null;
  }

  return deFormattedNumber;
}

export function formatPhoneE164(phoneNumber: string): string {
  let formatted = phoneNumber.replace(/\D/g, '');
  formatted = formatted.trim();

  if (formatted.length === 10) {
    return `+1${formatted}`;
  }

  return phoneNumber;
}

export function isNullOrEmptyOrUndefined(value: any): boolean {
  if (value === null || typeof value === 'undefined' || value === '' || value === 'undefined') {
    return true;
  }
  return false;
}

export function isNil(value: any): boolean {
  return [null, undefined, NaN].includes(value);
}

export function isString(value: any) {
  return !isNil(value) && typeof value == 'string';
}

export function isObject(value: any) {
  return !isNil(value) && typeof value == 'object';
}

export function isFunction(value: any) {
  return !isNil(value) && typeof value == 'function';
}

export function isArray(value: any) {
  return !isNil(value) && hasLength(value.length) && !isFunction(value);
}

export function range(start: number, size: number): ReadonlyArray<number> {
  return [...Array(size).keys()].map(i => i + start);
}

function hasLength(value: any) {
  return typeof value == 'number' && value > -1 && value % 1 == 0;
}

export function getSqlList(values: any[]) {
  return values.map(v => `'${v}'`).join(',');
}

export function deleteNilValues<T>(object: T): Partial<T> {
  const copy = clone(object);
  Object.keys(copy).forEach(key => isNil(copy[key]) ? delete copy[key] : {});
  return copy;
}

export function clone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function clamp(value: number, min: number, max: number) {
  return value <= min
    ? min
    : value >= max
      ? max
      : value;
}

export function getFulfilledPromiseValues<T>(results: PromiseSettledResult<T>[]) {
  return results
    ?.filter(p => p?.status === 'fulfilled')
    ?.map(p => <PromiseFulfilledResult<T>>p)
    ?.map(p => p?.value)
    ?.filter(p => !!p)
    ?? [];
}

export function getRejectedPromiseReasons<T>(results: PromiseSettledResult<T>[]) {
  return results
    ?.filter(p => p?.status === 'rejected')
    ?.map(p => <PromiseRejectedResult>p)
    ?.map(p => p?.reason)
    ?.filter(p => !!p)
    ?? [];
}

export function escapeSingleQuotes(value: string) {
  return value ? value.replace(new RegExp(`'`, 'g'), `''`) : '';
}

export function escapePercent(value: string) {
  return value ? value.replace(new RegExp('%', 'g'), '\\%') : '';
}

export function escapeDBString(value: string) {
  return value ? escapePercent(escapeSingleQuotes(value)) : '';
}

export function logDeep(...items: any[]) {
  console.log(util.inspect(items, { depth: 10 }));
}

export function capitalize(value: string) {
  return value.split(' ').map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' ');
};

export function chunkArray<T>(items: T[], size: number): T[][] {
  return chunk(items, size);
}

export interface BatchProcessRequest<T> {
  name: string;
  items: any[];
  size: number;
  parallel?: number;
  process: (chunk: any[]) => Promise<T>;
}

export async function batchProcess<T>({ name, items, size, process, parallel = 1 }: BatchProcessRequest<T>) {
  const time = name + ' ' + Date.now();
  try {
    if (!items || items.length <= 0) {
      return null;
    }

    console.time(time);

    const chunks = chunkArray(items, size);
    console.log(`${name}: Number of Chunks: ${chunks.length}`);

    const responses: T[] = [];

    for (let i = 0; i < chunks.length; i += parallel) {
      const indicies = range(i, parallel);
      console.log(`${name}: Processing Chunk #${indicies}`);
      const promises = indicies.filter(j => j < chunks.length).map(j => process(chunks[j]));
      responses.push(...await Promise.all(promises));
    }

    return responses;
  } catch (error) {
    console.timeEnd(time);
    console.error(`${name}:`, { error });
    throw error;
  }
}

export function getUniqueItemsByProp<T>(items: T[], prop: keyof T) {
  const values = items.map(item => item[prop]);
  return items.filter((item, index) => !values.includes(item[prop], index + 1));
}

export function getPopulatedString(value?: string) {
  const trimmed = value?.trim() ?? undefined;
  return !isEmpty(trimmed) ? trimmed : undefined;
}

export function hasPopulatedString(value?: string) {
  return !isNil(getPopulatedString(value));
}

export function isEmail(email: string): boolean {
  const regex: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
}

export function extendObject<T>(value: T, data: Partial<T>): T {
  return assign(cloneDeep(value), data ?? {});
}

export function chunkString(value: string, length: number) {
  const items = value?.match(new RegExp('(.|\n|\r|\t|\f){1,' + length + '}', 'gm')) ?? [];
  return items?.filter(item => !!item)?.map(item => item) ?? [];
}

export function unflatten(value: any): any {
  if (_isArray(value)) {
    return value?.map(item => unflatten(item)) ?? [];
  }

  switch (typeof value) {
    case 'string':
    case 'number':
    case 'bigint':
    case 'boolean':
    case 'symbol':
    case 'undefined':
      return value;

    case 'function':
      return undefined;

    default:
    case 'object':
      return Object.keys(value).reduce((result, key) => set(result, key, unflatten(value[key])), value);
  }
}

export interface PaginateRecordsRequest {
  sort?: {
    field: string;
    direction: 'desc' | 'asc';
  };
  skip: number;
  take: number;
}

export function paginateRecords<T>(records: T[], request: PaginateRecordsRequest): T[] {
  const ordered = request?.sort
    ? orderBy(
      records,
      request.sort.field,
      request.sort.direction,
    )
    : records;

  return take(drop(ordered, request.skip), request.take);
}

export type OptionalPromise<T> = Promise<T> | T;

export function resize<T>(items: (T | undefined)[], size: number): (T | undefined)[] {
  const copy = clone(items);

  if (copy?.length <= 0) {
    return [];
  }

  while (copy?.length > size) {
    copy?.pop();
  }

  while (copy.length < size) {
    copy?.push(undefined);
  }

  return copy;
}