import { IGetUsecasesResponse } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useTcrUsecases(): UseAxiosResult<IGetUsecasesResponse> {
  const data = useAxios<IGetUsecasesResponse>({
    url: 'tcr-enums/use-cases',
    method: 'GET'
  });
  return data;
}
