import { Button, ButtonVariantEnum, LoadingIndicator, TextInput } from '@Wonder-Cave/ui';
import { SystemNumberStatusEnum } from '@shared/enums/system-number-status-enum';
import { ISearchClientsRecord, ISearchLocationsRecord, ISearchLocationsRequest, ISearchTcrCampaignsRecord } from '@shared/models';
import { deFormatPhone } from '@shared/services';
import { Formik } from 'formik';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useLocations from '../../hooks/useLocations';
import { IDropdownValue } from '../shared/Form/Dropdown';
import RegionDropdown from '../shared/Form/Dropdowns/RegionDropdown';
import CustomShimmer from '../shared/Other/CustomShimmer';
import NumbersModal from './NumbersModal';
import { ISearchNumbersForm, getInitialSearchFormState, getSearchNumberSchema, getValidQuantity, purchaseSystemNumbers, searchSystemNumbers } from './types';

export interface IPurchaseNumbersProps {
  isReadOnly?: boolean;
  client?: ISearchClientsRecord;
  tcrCampaign?: ISearchTcrCampaignsRecord;
  isLoading: boolean;
  isActiveSystemNumbersLoading?: boolean;
  isDeactivateNumbersLoading?: boolean;
  activeSystemNumbers?: { number: string; status: SystemNumberStatusEnum; }[] | undefined;
  onPurchase: (numbers: string[]) => Promise<void>;
}

interface NumbersModalData {
  title: string;
  phoneNumbers: string[];
}

enum NumbersModalType {
  ACTIVE = 'ACTIVE',
  SELECTED = 'SELECTED'
}

const PurchaseNumbers = ({
  isReadOnly,
  client,
  tcrCampaign,
  isLoading,
  isActiveSystemNumbersLoading,
  isDeactivateNumbersLoading,
  activeSystemNumbers,
  onPurchase,
}: IPurchaseNumbersProps) => {
  const { addNotification } = useNotifications();

  const [selectedLocationDropdown, setSelectedLocationDropdown] = useState<IDropdownValue | undefined>(undefined);
  const [selectedSystemNumbers, setSelectedSystemNumbers] = useState<string[] | undefined>([]);
  const [numbersModalType, setNumbersModalType] = useState<NumbersModalType | undefined>(undefined);

  const [showNumbersModal, setShowNumbersModal] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isPurchaseNumbersLoading, setIsPurchaseNumbersLoading] = useState(false);

  const validSelectedSystemNumbers = selectedSystemNumbers
    ?.filter(selectedNumber => !activeSystemNumbers
      ?.map(activeNumber => deFormatPhone(activeNumber.number))
      ?.filter(activeNumber => !!activeNumber)
      ?.includes(deFormatPhone(selectedNumber) as string));

  const validSelectedSystemNumbersCount = validSelectedSystemNumbers?.length ?? 0;
  const activeSystemNumbersCount = activeSystemNumbers?.length ?? 0;

  const [{ data: citiesData, loading: citiesLoading }, searchCities] = useLocations({
    environment: tcrCampaign?.environment!,
    limit: 50
  });

  const numbersLoading = isSearchLoading || isPurchaseNumbersLoading || isDeactivateNumbersLoading;
  const canPurchaseNumbers = !(isReadOnly || numbersLoading || !tcrCampaign?.isActive || validSelectedSystemNumbersCount <= 0 || !tcrCampaign?.isActive);

  const isSinch = tcrCampaign?.provider?.name?.trim()?.toLowerCase()?.includes('sinch') ?? false;
  const showShimmer = !!isLoading || !!isActiveSystemNumbersLoading;

  const [initialFormState, setInitialFormState] = useState<ISearchNumbersForm | undefined>(getInitialSearchFormState(tcrCampaign!, client!, activeSystemNumbersCount));

  useEffect(() => {
    setInitialFormState(getInitialSearchFormState(tcrCampaign!, client!, activeSystemNumbersCount));
  }, [client, tcrCampaign, activeSystemNumbersCount]);

  const schema = getSearchNumberSchema({
    isSinch,
    city: selectedLocationDropdown?.additionalData?.city
  });

  const cityDropdownValues: IDropdownValue[] = citiesData?.records?.map(location => ({
    value: location?.id ?? '',
    label: [location?.city, location?.stateCode]?.filter(v => !!v)?.join(' - ') ?? 'N/A',
    additionalData: location
  })) ?? [];

  const searchPhoneNumbers = async (formData: ISearchNumbersForm) => {
    try {
      setIsSearchLoading(true);
      setSelectedSystemNumbers([]);

      const response = await searchSystemNumbers({ ...formData, isSinch });
      const phoneNumbers = response?.data?.numbers?.map(number => deFormatPhone(number)) as string[] ?? [];
      return setSelectedSystemNumbers(phoneNumbers);
    } catch (error: any) {
      console.error(error);

      if (error?.constructor?.name === 'Cancel') {
        return;
      }

      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to search for System Numbers.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsSearchLoading(false);
    }
  };

  const purchasePhoneNumbers = async () => {
    try {
      setIsPurchaseNumbersLoading(true);

      if (validSelectedSystemNumbersCount > 0) {
        await purchaseSystemNumbers(tcrCampaign, validSelectedSystemNumbers);

        await onPurchase(validSelectedSystemNumbers ?? []);

        addNotification({
          header: 'System Numbers purchased successfully!',
        });

        setSelectedSystemNumbers([]);
      }
    } catch (error: any) {
      console.error(error);

      if (error?.constructor?.name === 'Cancel') {
        return;
      }

      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to purchase the System Numbers.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsPurchaseNumbersLoading(false);
    }
  };

  const searchAllCities = async (search: string) => {
    try {
      const searchRequest: ISearchLocationsRequest = {
        environment: tcrCampaign?.environment!,
        city: search,
        limit: 50
      };

      await searchCities({ data: searchRequest });
    } catch (error) {
      console.error(error);

      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to search Cities.',
        type: NotificationType.FAILURE,
      });
    } finally {
    }
  };

  const getActiveNumberMessage = () => {
    const plural = !isNil(activeSystemNumbersCount) && activeSystemNumbersCount > 1 || activeSystemNumbersCount === 0;
    return `${activeSystemNumbersCount} active number${plural ? 's' : ''}`;
  };

  const openSelectedNumbersModal = () => {
    if (validSelectedSystemNumbersCount > 0) {
      setNumbersModalType(NumbersModalType.SELECTED);
      setShowNumbersModal(true);
    }
  };

  const openActiveNumbersModal = () => {
    if (activeSystemNumbersCount > 0) {
      setNumbersModalType(NumbersModalType.ACTIVE);
      setShowNumbersModal(true);
    }
  };

  const getValidAreaCode = (val?: any) => {
    // Get first 3 numbers
    const value = val?.replace(/\D/g, '')?.substring(0, 3) ?? '';
    return value?.startsWith('0') ? undefined : value;
  };

  const getNumbersModalNumbers = (): string[] => {
    return numbersModalType === NumbersModalType.ACTIVE
      ? activeSystemNumbers?.map((systemNumber) => systemNumber.number) ?? []
      : validSelectedSystemNumbers ?? [];
  };

  const getNumbersModalTitle = (): string => {
    return numbersModalType === NumbersModalType.ACTIVE
      ? 'Active Numbers'
      : 'Selected Numbers';
  };

  return <Formik
    enableReinitialize
    validateOnBlur
    validateOnMount
    validateOnChange
    validationSchema={schema}
    initialValues={initialFormState!}
    initialTouched={{ areaCode: !!client?.areaCode }}
    onSubmit={(formData: ISearchNumbersForm) => searchPhoneNumbers(formData)}
  >
    {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty }) => (
      <form id="phone-number-search" onSubmit={handleSubmit} className='flex flex-col w-full pr-8'>
        <h1>
          Purchase Numbers
        </h1>

        <CustomShimmer isVisible={showShimmer} className='h-5 mb-8' element={
          <div className="flex flex-row">
            <h3 className={`pb-8 ${activeSystemNumbersCount > 0 ? 'text-wc-blue cursor-pointer' : 'text-medium-gray'}`} onClick={() => openActiveNumbersModal()}>
              {getActiveNumberMessage()}
            </h3>

            {isActiveSystemNumbersLoading && <LoadingIndicator size={4} className='m-2' />}
          </div>
        } />

        <div className='flex flex-row justify-between pb-8'>
          {!isSinch && <div className='flex flex-col w-full pr-8'>
            <h5 className='pb-4 text-medium-gray'>CITY</h5>

            <CustomShimmer isVisible={showShimmer} className='h-10 mb-4' element={
              <RegionDropdown
                options={cityDropdownValues}
                onBlur={() => {
                  setFieldTouched('city');
                  setFieldTouched('state');
                }}
                onChange={(newValue) => {
                  const record: ISearchLocationsRecord = newValue?.additionalData;

                  setFieldTouched('city');
                  setFieldTouched('state');

                  setFieldValue('city', record?.city);
                  setFieldValue('state', record?.stateCode);
                  setSelectedLocationDropdown(newValue);
                }}
                refreshOptions={async (search) => searchAllCities(search)}
                value={selectedLocationDropdown}
                disabled={isReadOnly || isSearchLoading || isSinch || !tcrCampaign?.isActive}
                loading={citiesLoading}
                showError={(!!touched?.city && !!errors?.city) || (!!touched?.state && !!errors?.state)}
                errorMessage={errors?.city ?? errors?.state}
                errorLocation={'BOTTOM'}
              />
            } />
          </div>}

          <div className='flex flex-col w-full'>
            <h5 className='pb-4 text-medium-gray'>AREA CODE</h5>

            <CustomShimmer isVisible={showShimmer} className='h-10 mb-4' element={
              <TextInput
                id="areaCode"
                name="areaCode"
                type="text"
                disabled={isReadOnly || isSearchLoading || !tcrCampaign?.isActive}
                value={values?.areaCode as any}
                error={touched?.areaCode ? errors?.areaCode : ''}
                clearable
                onBlur={(event) => {
                  setFieldTouched('areaCode');
                  setFieldValue('areaCode', getValidAreaCode(event?.target?.value));

                  handleBlur(event);
                }}
                onChange={(val) => {
                  setFieldTouched('areaCode');
                  setFieldValue('areaCode', getValidAreaCode(val?.target?.value));
                }}
              />
            } />
          </div>
        </div>

        <div className='flex flex-col pb-4'>
          <h5 className='pb-4 text-medium-gray'>QUANTITY</h5>

          <CustomShimmer isVisible={showShimmer} className='h-12 mb-4' element={
            <TextInput
              className='bg-gray-100 border-grey-300 focus:outline-none'
              id="limit"
              name="limit"
              type='number'
              placeholder="Quantity"
              value={values?.limit as any}
              error={touched?.limit ? errors?.limit : ''}
              clearable={true}
              onBlur={(event) => {
                const newValue = getValidQuantity(activeSystemNumbersCount, event?.target?.value);
                setFieldTouched('limit');
                setFieldValue('limit', newValue);

                handleBlur(event);
              }}
              onClear={() => {
                setFieldTouched('limit');
                setFieldValue('limit', undefined);
              }}
              disabled={isReadOnly || isSearchLoading || !tcrCampaign?.isActive}
              onChange={(event) => {
                const newValue = getValidQuantity(activeSystemNumbersCount, event?.target?.value);
                setFieldTouched('limit');
                setFieldValue('limit', newValue);
              }}
            />
          } />
        </div>

        <div className='flex flex-row justify-between'>
          <div className='flex flex-row items-center justify-between'>
            <Button
              type="submit"
              variant={ButtonVariantEnum.PRIMARY}
              isLoading={isSearchLoading}
              disabled={isReadOnly || numbersLoading || (!dirty && !values?.areaCode) || !isValid || !tcrCampaign?.isActive}>
              SEARCH
            </Button>
            {!isSearchLoading && <CustomShimmer isVisible={showShimmer} className='h-3 mb-4 ml-8' element={
              <h3 className={`ml-8 ${validSelectedSystemNumbersCount > 0 ? 'text-wc-blue cursor-pointer' : ''}`} onClick={() => openSelectedNumbersModal()}>
                {`${validSelectedSystemNumbersCount} Numbers Selected`}
              </h3>
            } />}
          </div>

          <div className='flex flex-row items-center justify-between'>
            <Button
              onClick={() => purchasePhoneNumbers()}
              type={'button'}
              isLoading={isPurchaseNumbersLoading}
              variant={ButtonVariantEnum.TERTIARY}
              disabled={!canPurchaseNumbers}
              className={`${canPurchaseNumbers ? 'cursor-pointer' : 'cursor-auto'}`}
            >
              PURCHASE
            </Button>
          </div>
        </div>

        <NumbersModal
          title={getNumbersModalTitle()}
          show={showNumbersModal}
          phoneNumbers={getNumbersModalNumbers()}
          onClose={() => setShowNumbersModal(false)}
        />
      </form>
    )}
  </Formik>;
};

export default PurchaseNumbers;

