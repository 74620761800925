import { Button, ButtonVariantEnum } from '@Wonder-Cave/ui';
import { TcrCampaignStage } from '@shared/enums';
import { ISearchClientsRecord, ISearchTcrCampaignsRecord } from '@shared/models';
import { TcrRegistryCampaignStatus } from '@shared/models/domain/clients/tcr-campaign.interface';
import CustomShimmer from '../shared/Other/CustomShimmer';

export interface TCRCampaignDetailsHeaderProps {
  isReadOnly?: boolean;
  client?: ISearchClientsRecord;
  tcrCampaign?: ISearchTcrCampaignsRecord;
  onStatusToggle: () => void;
  isLoadingStatus: boolean;
  isLoading: boolean;
}

const TCRCampaignDetailsHeader = ({
  isReadOnly,
  client,
  tcrCampaign,
  onStatusToggle,
  isLoadingStatus,
  isLoading
}: TCRCampaignDetailsHeaderProps) => {
  const canToggle = !!tcrCampaign?.isActive && tcrCampaign?.tcrCampaign?.status !== TcrRegistryCampaignStatus.EXPIRED;
  const disabled = isReadOnly || isLoading || !tcrCampaign?.isActive || tcrCampaign?.tcrCampaign?.status === TcrRegistryCampaignStatus.EXPIRED;

  const getStageDescription = (tcrCampaign?: ISearchTcrCampaignsRecord) => {
    if (isLoading) {
      return 'Loading...';
    }

    switch (tcrCampaign?.stage) {
      case TcrCampaignStage.CAMPAIGN_ADD:
        return 'Pending';
      case TcrCampaignStage.CAMPAIGN_SHARE_ACCEPT:
        return 'Pending';
      case TcrCampaignStage.CAMPAIGN_DCA_COMPLETE:
        return 'Approved';
      case TcrCampaignStage.CAMPAIGN_SHARE_DELETE:
        return 'Rejected';
      default:
        return 'N/A';
    }
  };

  return <div id='header' className='flex flex-row justify-between pb-16'>
    <div id='header-description' className='flex flex-col'>
      <CustomShimmer isVisible={isLoading} className='h-12 mb-4' element={
        <h1 className='pb-4' id='header-name'>
          {tcrCampaign?.description ?? 'N/A'}
        </h1>
      } />

      <h3 id='header-stage' className='text-wc-blue'>
        Registration Stage: {getStageDescription(tcrCampaign)}
      </h3>
    </div>

    <div id='header-buttons' className='flex flex-row'>

      {canToggle &&
        <Button
          id='header-button-toggle'
          className='ml-4 mr-4'
          onClick={onStatusToggle}
          type={'button'}
          variant={ButtonVariantEnum.SECONDARY}
          disabled={disabled || isLoadingStatus}
          isLoading={isLoadingStatus}>
          TCR {tcrCampaign?.isActive ? 'DISABLE' : 'ENABLE'}
        </Button>
      }

      <Button
        id='header-button-export'
        onClick={() => { }}
        type={'button'}
        disabled={disabled}
        variant={ButtonVariantEnum.TERTIARY}>
        EXPORT PHONE NUMBERS
      </Button>
    </div>
  </div>;
};

export default TCRCampaignDetailsHeader;

