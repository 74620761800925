import { StrictMode, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './styles.scss';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import { AppNotFound } from './components/shared/Auth/PageNotFound';
import { auth0Audience, auth0Scope } from './providers/auth0.service';
import { getAuth0Config, getSubDomain } from './providers/sub-domain.service';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

function Auth0App(): JSX.Element {

  const [config, setConfig] = useState(getAuth0Config());
  const invitation = getParam('invitation') ?? undefined;
  const organization = getParam('organization') ?? undefined;

  if (!config) {
    return (
      <Routes>
        <Route element={AppNotFound} />
      </Routes>
    );
  }

  return (
    <Auth0Provider
      domain="wondercave.us.auth0.com"
      clientId={config.clientId}
      redirectUri={window.location.origin}
      invitation={invitation}
      organization={organization ?? config.organizationId}
      cacheLocation="localstorage"
      scope={auth0Scope}
      audience={auth0Audience}
    >
      <App />
    </Auth0Provider>
  );
}

function getParam(name: string) {
  const params = new URLSearchParams(useLocation().search);
  return params.has(name) ? params.get(name) : undefined;
}
