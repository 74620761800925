import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { INotification, NotificationType, useNotifications } from '../contexts/NotificationContext';

const NotificationsRegion = (): JSX.Element => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-30 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
        {notifications.map((notification: INotification) => (
          <Transition
            key={notification.id}
            show={notification?.isVisible}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* Notification Content */}
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {notification.type === NotificationType.FAILURE ? (
                      <XCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />
                    ) : notification.type === NotificationType.INFO ? (
                      <InformationCircleIcon className="w-6 h-6 text-sky-400" aria-hidden="true" />
                    ) : (
                      <CheckCircleIcon className="w-6 h-6 text-emerald-400" aria-hidden="true" />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{notification.header}</p>
                    <p className="mt-1 text-sm text-gray-500">{notification.content}</p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      onClick={() => removeNotification(notification)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
};

export default NotificationsRegion;
