import { Icon } from './Icon';

export const Outbox = () => (
  <Icon nav>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46 33" fill="none">
      <path
        d="M2.59999 0.0553894C1.77293 0.315903 0.919111 0.981022 0.506051 1.70012C-0.0421934 2.63467 -0.0154384 1.80761 0.0117861 16.5656L0.0385411 30.038L0.335194 30.6402C0.694744 31.386 1.27866 31.9793 2.00668 32.3567L2.55493 32.6445H22.9568H43.3587L43.8802 32.4018C44.8509 31.9437 45.5339 31.1706 45.8305 30.1821C46.0103 29.5977 46.0103 29.4001 45.9925 16.0624L45.9657 2.5361L45.678 1.9428C45.3274 1.2237 44.6805 0.594719 43.9614 0.26239L43.4489 0.0197182L23.1371 0.00188065C11.9652 -0.00750732 2.72578 0.019249 2.59999 0.0553894ZM42.9814 2.16716C43.2958 2.29296 43.853 2.86843 43.853 3.06604C43.853 3.24582 24.0622 18.5605 23.622 18.7135C23.1728 18.8755 22.5344 18.8844 22.103 18.7314C21.6806 18.5783 2.15031 3.39837 2.15031 3.21859C2.15031 2.967 2.61782 2.40984 2.98629 2.23006C3.36368 2.04137 3.36368 2.04137 23.0287 2.04137C39.3417 2.04137 42.7387 2.05967 42.9814 2.16716ZM43.9075 28.7077C43.8535 28.7706 29.2485 17.4011 29.2485 17.3026C29.2485 17.2575 32.5469 14.6782 36.5733 11.5685L43.8981 5.92414L43.9253 17.3026C43.9342 23.5581 43.9253 28.6899 43.9075 28.7077ZM9.59245 11.829C13.5114 14.858 16.7107 17.3654 16.7107 17.4011C16.7018 17.4551 2.36623 28.6627 2.2226 28.7166C2.18646 28.7345 2.15078 23.6477 2.15078 17.41C2.15078 6.49914 2.1597 6.07669 2.31272 6.19357C2.40237 6.25646 5.674 8.79116 9.59245 11.829ZM35.2613 24.7804C39.8003 28.3036 42.8021 30.6942 42.7392 30.7303C42.6044 30.8204 3.34631 30.8204 3.28341 30.7303C3.25619 30.6763 18.0409 19.0825 18.4634 18.8309C18.5352 18.7858 18.9398 19.0557 19.551 19.55C20.0903 19.9813 20.7911 20.4578 21.115 20.6014C21.6721 20.8619 21.7529 20.8713 22.8676 20.8713C24.3777 20.8624 24.656 20.7544 26.1839 19.5683C27.1996 18.7863 27.3705 18.6877 27.5141 18.7863C27.6038 18.8393 31.0819 21.5449 35.2613 24.7804Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
