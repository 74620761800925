import { IColumn } from '@Wonder-Cave/ui';
import { EntityName } from '@shared/enums';
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';


export const optInTableTake = 10;

export const defaultOptoutTableOptions: ISearchRequest = {
  entity: EntityName.ALL_CLIENTS_VIEW,
  filters: [],
  pagination: {
    skip: 0,
    take: optInTableTake,
  },
  sort: {
    fieldName: 'phoneNumber',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Phone Number',
      fieldName: 'phoneNumber',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.NUMBER,
      renderColumn: columnRenders['phoneNumber'],
    },
    {
      headerName: '',
      fieldName: 'tenantsAndClients',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['tenantsAndClients'],
    }
  ];
};