import { Button } from './Buttons/Button';
import { ButtonVariantEnum } from './Buttons/types';

interface ICardProps {
  header?: string | React.ReactNode;
  description?: string;
  children?: React.ReactNode;
  padded?: boolean;
  className?: string;
  allowOverflow?: boolean;
  action?: () => void;
  actionText?: string;
  actionOnHeaderClick?: boolean; // If true, the action will happen when the header is clicked and no button will show
}

const Card = ({
  header,
  description,
  children,
  padded = true,
  className,
  allowOverflow,
  action,
  actionText,
  actionOnHeaderClick = false,
}: ICardProps): JSX.Element => {
  return (
    <div
      className={`${
        className ?? ''
      } bg-white dark:bg-slate-700 dark:border-2 dark:border-slate-800 sm:rounded-lg sm:shadow ${
        allowOverflow ? '' : 'overflow-hidden'
      }`}
    >
      {header && (
        <div
          className="px-4 py-5 border-b border-gray-200 dark:border-slate-800 sm:px-6"
          onClick={() => {
            actionOnHeaderClick && action && action();
          }}
        >
          <div className="flex flex-wrap items-center justify-between -mt-4 -ml-4 sm:flex-nowrap">
            <div className="mt-4 ml-4">
              <h3
                className={`text-lg font-medium leading-6 ${
                  actionOnHeaderClick && action
                    ? 'text-sky-600 dark:text-white hover:underline cursor-pointer'
                    : 'text-gray-900 dark:text-slate-400'
                }`}
              >
                {header}
              </h3>

              {description && <p className="mt-1 text-sm text-gray-500 dark:text-slate-400">{description}</p>}
            </div>

            {action && !actionOnHeaderClick && (
              <div className="flex-shrink-0 mt-4 ml-4">
                <Button variant={ButtonVariantEnum.SECONDARY} type="button" onClick={action} text={actionText} />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={`${padded && 'px-4 py-5'}`}>{children}</div>
    </div>
  );
};

export { Card };
