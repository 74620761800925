import { User, useAuth0 } from '@auth0/auth0-react';
import { Disclosure } from '@headlessui/react';
import { Auth0Role } from '@shared/models';
import { checkAuth0Roles } from '@shared/services';
import { useMembership } from 'apps/gsd-portal/src/contexts/MembershipContext';
import { SidebarContext } from 'apps/gsd-portal/src/contexts/SidebarContext';
import { logout } from 'apps/gsd-portal/src/providers/auth0.service';
import {
  getSubDomain,
  getSubDomainLabel
} from 'apps/gsd-portal/src/providers/sub-domain.service';
import { useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IDropdownValue } from '../Form/Dropdown';
import { Calculator as CalculatorIcon } from '../Icons/Calculator';
import { Clients as ClientsIcon } from '../Icons/Clients';
import { Edit as EditIcon } from '../Icons/Edit';
import { Logout as LogoutIcon } from '../Icons/Logout';
import { Outbox as OutboxIcon } from '../Icons/Outbox';
import { PhoneIcon } from '../Icons/Phone';
import Festive from '../Other/Festive';
import Shimmer, { ShimmerType } from '../Shimmer';
import { MenuButton } from './MenuButton';
import { MenuItem } from './MenuItem';

export interface INavItem {
  name: string;
  icon?: any; // (props: SVGProps<SVGSVGElement>) => JSX.Element;
  href?: string;
  visible?: boolean;
  children?: INavItem[];
}

const LeftNavContent = (): JSX.Element => {
  const location = useLocation();
  const { devMode, sidebarOpen, setDevMode, setSidebarOpen, activeUser } = useContext(SidebarContext);
  const { logout: auth0Logout, user: auth0User, isLoading } = useAuth0();
  const { membership, isLoaded } = useMembership();
  const openDisc = useRef<HTMLButtonElement>();
  const subDomain = getSubDomain();
  const [subDomainDropdownValue, setSubDomainDropdownValue] = useState({
    label: getSubDomainLabel(subDomain),
    value: subDomain,
  } as IDropdownValue);
  const userRoles = membership?.roles ?? [];

  const getNavbarItems = (): INavItem[] => {
    const navigation: INavItem[] = [
      {
        name: 'MPS Tracker',
        href: '/mps-tracker',
        icon: OutboxIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GSD_ADMIN] }),
      },
      {
        name: 'MPS Calculator',
        href: '/mps-calculator',
        icon: CalculatorIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GSD_ADMIN] })
      },
      {
        name: 'MPS Editor',
        href: '/mps-editor',
        icon: EditIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GSD_ADMIN] })
      },
      {
        name: 'Clients',
        href: '/clients',
        icon: ClientsIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GSD_ADMIN] })
      },
      {
        name: 'Opt-outs',
        href: '/opt-outs',
        icon: PhoneIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GSD_ADMIN] })
      }
    ];
    return navigation;
  };

  const getFullName = (user?: User) => {
    const firstName = activeUser?.firstName ?? user?.given_name ?? user?.app_metadata?.firstName;
    const lastName = activeUser?.lastName ?? user?.family_name ?? user?.app_metadata?.lastName;
    return [firstName?.trim(), lastName?.trim()].filter((n) => n).join(' ');
  };

  const navItems: INavItem[] = getNavbarItems();

  return (
    <div id="nav-content" className="justify-between nav-bar">
      {devMode && <Festive />}
      <div className="mt-10 nav-menu">
        <img
          id="logo"
          className="w-auto h-10"
          src={`/GSDLogo.svg`}
          alt="GSD Logo"
        />
        <nav
          className={`flex flex-col items-center flex-1 px-2 space-y-2 bg-white dark:bg-slate-800`}
          aria-label="Sidebar"
        >
          {!isLoaded ? (
            <Shimmer type={ShimmerType.LIST} rows={5} />
          ) : (
            <>
              {navItems.map(
                (item) =>
                  item.visible &&
                  (!item.children ? (
                    <MenuItem key={item.name} item={item} active={item.href === location.pathname} />
                  ) : (
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1"
                      defaultOpen={!!item.children?.find((ch) => ch.href === location.pathname)}
                    >
                      {({ open, close }) => (
                        <>
                          <MenuButton
                            onClick={(e) => {
                              // Headless does not expose ways to imperatively close other panels, this is a work around
                              if (openDisc.current && openDisc.current.id !== e.currentTarget.id) {
                                // We're opening a nav item when a seperate one is already open here
                                openDisc.current.click();
                                openDisc.current = e.currentTarget;
                              } else if (openDisc.current) {
                                // We're closing a nav item here
                                openDisc.current = undefined;
                              } else {
                                // We're opening a nav item here
                                openDisc.current = e.currentTarget;
                              }
                            }}
                            key={item.name}
                            item={item}
                            active={item.href === location.pathname}
                            open={open}
                          />
                          <Disclosure.Panel className="space-y-1">
                            {item.children?.map(
                              (subItem) =>
                                subItem?.visible && (
                                  <MenuItem
                                    key={subItem.name}
                                    item={subItem}
                                    active={subItem.href === location.pathname}
                                  />
                                )
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))
              )}
            </>
          )}
        </nav>
      </div>
      <div className="flex flex-col flex-shrink-0 px-2 pb-10 mt-4">
        {getFullName(auth0User).length > 0 && (
          <div className="flex flex-col items-center">
            <div className="button-text text-wc-blue">{`${getFullName(auth0User).toLocaleUpperCase()}`}</div>
            <div className="body-text-small">{auth0User?.email ?? ''}</div>
          </div>
        )}
        <div className="flex justify-center mt-5 space-x-8 align-middle">
          <div
            className="cursor-pointer"
            onClick={() => {
              logout({ auth0Logout, auth0User });
            }}
          >
            <LogoutIcon />
          </div>
        </div>
        <div className="mt-12 text-tiny">{`© ${new Date().getFullYear()}, Wondercave. All rights reserved.`}</div>
      </div>
    </div>
  );
};

export default LeftNavContent;
