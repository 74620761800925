import { GREnvironment, IDeactivateSystemNumbersRequest, IPurchaseSystemNumbersRequest, ISearchClientsRecord, ISearchSystemNumbersRequest, ISearchSystemNumbersResponse, ISearchTcrCampaignsRecord } from '@shared/models';
import { deFormatPhone, deleteNilValues } from '@shared/services';
import { isEmpty, isNil } from 'lodash';
import * as yup from 'yup';
import { axiosPost } from '../../authAxios';

export interface GetSearchNumberSchemaRequest {
  isSinch: boolean | undefined;
  city: string;
}

export function getSearchNumberSchema(request: GetSearchNumberSchemaRequest) {
  return yup.object().shape({
    environment: yup.mixed<GREnvironment>().oneOf(Object.values(GREnvironment)).required('Environment is required'),
    tcrCampaignId: yup.string().uuid().required('TCR Campaign ID is required'),
    limit: yup.number().required('Quantity is required')
      .min(0, 'Must be >= 0')
      .max(49, 'Must be <= 49'),
    city: yup.string()
      .when(['areaCode'], {
        is: (areaCode: string) => !request?.isSinch && areaCode?.length !== 3,
        then: (schema) => schema.required('City is required'),
        otherwise: (schema) => schema.optional(),
      }),
    state: yup.string()
      .when(['areaCode'], {
        is: (areaCode: string) => !request?.isSinch && areaCode?.length !== 3,
        then: (schema) => schema.required('State is required'),
        otherwise: (schema) => schema.optional(),
      }),
    areaCode: yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .length(3, 'Must be exactly 3 digits')
      .when([], {
        is: () => !request?.city,
        then: (schema) => schema.required('Area Code is required'),
        otherwise: (schema) => schema.optional(),
      }),
  });
}

export interface ISearchNumbersForm {
  environment: GREnvironment;
  tcrCampaignId: string;
  limit?: number;
  city?: string;
  state?: string;
  areaCode?: string;
}

export function getInitialSearchFormState(tcrCampaign: ISearchTcrCampaignsRecord, client: ISearchClientsRecord, activeNumberCount: number): ISearchNumbersForm {
  return {
    environment: tcrCampaign?.environment!,
    tcrCampaignId: tcrCampaign?.id!,
    limit: getValidQuantity(activeNumberCount, 49),
    city: undefined,
    state: undefined,
    areaCode: client?.areaCode?.toString(),
  };
};

export function getValidQuantity(activeNumberCount: number, value?: any) {
  const max = Math.max(49 - activeNumberCount, 0);

  return isNil(value) || value <= 0
    ? undefined
    : Math.min(Number(value), max ?? 0);
};

export interface SearchSystemNumbersData extends ISearchNumbersForm {
  isSinch?: boolean;
}

export async function searchSystemNumbers(data?: SearchSystemNumbersData) {
  const request: ISearchSystemNumbersRequest = {
    environment: data?.environment!,
    tcrCampaignId: data?.tcrCampaignId!,
    limit: data?.limit!,
    city: (isEmpty(data?.city) || !!data?.isSinch) ? undefined : data?.city,
    state: (isEmpty(data?.state) || !!data?.isSinch) ? undefined : data?.state,
    areaCode: isEmpty(data?.areaCode?.toString()) ? undefined : data?.areaCode,
  };

  return await axiosPost<ISearchSystemNumbersResponse>(`/tcr-campaigns/${request?.tcrCampaignId}/system-numbers/search`, deleteNilValues(request));
}

export async function purchaseSystemNumbers(tcrCampaign: ISearchTcrCampaignsRecord | undefined, phoneNumbers: string[] | undefined) {
  const request: IPurchaseSystemNumbersRequest = {
    environment: tcrCampaign?.environment!,
    tcrCampaignId: tcrCampaign?.id!,
    phoneNumbers: phoneNumbers?.map(phoneNumber => `+1${deFormatPhone(phoneNumber)}`) ?? []
  };

  return await axiosPost<void>(`/tcr-campaigns/${request?.tcrCampaignId}/system-numbers/purchase`, request);
}

export async function deactivateSystemNumbers(form: IDeactivateNumbersForm) {
  const request: IDeactivateSystemNumbersRequest = {
    environment: form?.environment!,
    tcrCampaignId: form?.tcrCampaignId!,
    phoneNumbers: form?.phoneNumbers ?? []
  };

  return await axiosPost<void>(`/tcr-campaigns/${request?.tcrCampaignId}/system-numbers/deactivate`, request);
}

export function formatPhoneNumber(value: string) {
  const digits = ('' + value).replace(/\D/g, '');
  const match = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
}

export interface IDeactivateNumbersForm {
  environment: GREnvironment;
  tcrCampaignId: string;
  phoneNumbers: string[];
}

export const deactivateNumbersSchema = yup.object().shape({
  environment: yup.mixed<GREnvironment>().oneOf(Object.values(GREnvironment)).required('Environment is required'),
  tcrCampaignId: yup.string().uuid().required('TCR Campaign ID is required'),
  phoneNumbers: yup.array().of(yup.string().matches(/^\d{10}$/)).min(1, 'Must selected at least one Number').required('Must selected at least one Number')
});
