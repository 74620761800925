import React, { useContext, useRef, useState } from 'react';

export enum NotificationType {
  SUCCESS,
  FAILURE,
  INFO,
}

interface INotification {
  id?: number;
  header?: string;
  icon?: any;
  content?: string;
  isVisible?: boolean;
  type?: NotificationType;
}

interface INotificationContext {
  notifications: INotification[];
  addNotification: (notification: INotification) => void;
  removeNotification: (notification: INotification) => void;
}

const NotificationsContext = React.createContext<INotificationContext>({
  notifications: [],
  addNotification: (notification: INotification) => {},
  removeNotification: (notification: INotification) => {},
});

const NotificationsProvider = ({ children }: any) => {
  const [notifications, setNotifications] = useState([] as INotification[]);
  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications;

  const addNotification = (newNotification: INotification): void => {
    const id = 1 + (notifications[notifications.length - 1]?.id || 0);
    const identifiedNotification = {
      ...newNotification,
      id: id,
      isVisible: true,
    };

    setTimeout(() => {
      const staleNotification = notificationsRef.current.find((notification) => notification.id === id)!;
      staleNotification.isVisible = false;
      setNotifications([...notificationsRef.current]);

      setTimeout(() => {
        setNotifications([...notificationsRef.current.filter((notification) => notification.id !== id)]);
      }, 1000);
    }, 5000);

    if (notifications.length >= 3) {
      removeNotification(notifications.find((notification) => notification.isVisible) || notifications[0]);
    }

    setNotifications([...notifications, identifiedNotification]);
  };

  const removeNotification = (oldNotification: INotification): void => {
    const staleNotification = notifications.find((notification) => notification.id === oldNotification.id)!;
    staleNotification.isVisible = false;
    setNotifications([...notifications]);
  };

  return (
    <NotificationsContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);

export { NotificationsContext, NotificationsProvider, useNotifications, INotification };
