import { Shimmer, ShimmerType } from '@Wonder-Cave/ui';

export interface CustomShimmerProps {
  isVisible: boolean;
  className: string;
  element: JSX.Element;
}

const CustomShimmer = ({
  isVisible,
  className,
  element,
}: CustomShimmerProps) => {
  return isVisible
    ? <Shimmer type={ShimmerType.CUSTOM} className={`bg-gray-200 rounded-lg dark:bg-slate-400 animate-pulse ${className ?? ''}`} />
    : element;
};

export default CustomShimmer;