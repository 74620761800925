import { ToggleSwitch, ToggleSwitchLabelLocationEnum } from '@Wonder-Cave/ui';
import { GRTcrCampaign, ISearchClientsRecord, ISearchTcrCampaignsRecord, IUpdateTcrCampaignRequest, IUpdateTcrCampaignResponse } from '@shared/models';
import { isNil } from 'lodash';
import { useState } from 'react';
import { axiosPut } from '../../authAxios';
import CustomShimmer from '../shared/Other/CustomShimmer';

export interface TCRCampaignDetailsMainProps {
  isReadOnly?: boolean;
  client?: ISearchClientsRecord;
  tcrCampaign?: ISearchTcrCampaignsRecord;
  isLoading: boolean;
}

const TCRCampaignDetailsMain = ({
  isReadOnly,
  client,
  tcrCampaign,
  isLoading
}: TCRCampaignDetailsMainProps) => {

  const [isActiveLoading, setIsActiveLoading] = useState<boolean>(false);

  return <div id='details' className='flex flex-row justify-between'>
    <div id='details-col-1' className='flex flex-col'>
      <div id='details-client' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>CLIENT</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{client?.name ?? 'N/A'}</div>
        } />
      </div>

      <div id='details-status' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>STATUS</h5>

        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='flex flex-row justify-start'>
            <div className='[&>div>label]:mr-0 [&>div]:ml-0 cursor-default'>
              <ToggleSwitch
                checked={!!tcrCampaign?.isActive}
                disabled={isLoading || isActiveLoading}
                checkedColor={isActiveLoading ? 'bg-mystic' : 'bg-turquoise'}
                uncheckedColor={isActiveLoading ? 'bg-mystic' : 'bg-silver'}
                onChange={async () => {
                  function toggle(tcrCampaign: ISearchTcrCampaignsRecord) {
                    setIsActive(tcrCampaign, !tcrCampaign.isActive);
                  }

                  function setIsActive(tcrCampaign: ISearchTcrCampaignsRecord, value: boolean) {
                    tcrCampaign.isActive = value;
                  }

                  if (tcrCampaign) {
                    // toggle ui for immediate feedback
                    setIsActiveLoading(true);
                    toggle(tcrCampaign);

                    try {
                      const request: IUpdateTcrCampaignRequest = {
                        environment: tcrCampaign.environment,
                        id: tcrCampaign.id,
                        isActive: tcrCampaign.isActive,
                        isFullDisable: false // don't disable real tcr from grid
                      };

                      await axiosPut<IUpdateTcrCampaignResponse>(`/tcr-campaigns/${tcrCampaign.id}`, request);
                    } catch (error: any) {
                      console.error('Error updating status:', error);

                      const currentTcrCampaign: GRTcrCampaign = error?.response?.data?.tcrCampaign;

                      currentTcrCampaign
                        ? setIsActive(tcrCampaign, !!currentTcrCampaign?.isActive)
                        : toggle(tcrCampaign);
                    } finally {
                      setIsActiveLoading(false);
                    }
                  }

                }}
                labelLocation={ToggleSwitchLabelLocationEnum.LEFT}
              />
            </div>

            <div className='pl-4 text-dark-gray'>{
              isNil(tcrCampaign?.isActive)
                ? 'N/A'
                : (tcrCampaign?.isActive ? 'Enabled' : 'Disabled')
            }</div>
          </div>
        } />
      </div>
    </div>

    <div id='details-col-2' className='flex flex-col'>
      <div id='details-tcr-id' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray' >TCR ID</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{tcrCampaign?.tcrCampaignId ?? 'N/A'}</div>
        } />
      </div>

      <div id='details-city' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>REGISTERED CITY</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{tcrCampaign?.tcrBrand?.city ?? 'N/A'}</div>
        } />
      </div>
    </div>

    <div id='details-col-3' className='flex flex-col'>
      <div id='details-provider' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>PROVIDER</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{tcrCampaign?.provider?.name ?? 'N/A'}</div>
        } />
      </div>

      <div id='details-state' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>STATE</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{tcrCampaign?.tcrBrand?.state ?? 'N/A'}</div>
        } />
      </div>
    </div>

    <div id='details-col-4' className='flex flex-col'>
      <div id='details-message' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>MESSAGE</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{tcrCampaign?.messageType ?? 'N/A'}</div>
        } />
      </div>

      <div id='details-area-codes' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>AREA CODE</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='break-words text-dark-gray text-wrap'>{client?.areaCode ?? 'N/A'}</div>
        } />
      </div>
    </div>

    <div id='details-col-5' className='flex flex-col'>
      <div id='details-campaign' className='flex flex-col pb-8 pr-8'>
        <h5 className='pb-4 text-medium-gray'>CAMPAIGN</h5>
        <CustomShimmer isVisible={isLoading} className='h-5' element={
          <div className='text-dark-gray'>{tcrCampaign?.campaignType ?? 'N/A'}</div>
        } />
      </div>
    </div>
  </div >;
};

export default TCRCampaignDetailsMain;
