import { FilterDataTypeEnum } from '@shared/models';
import { convertEnumToReadableString } from '../../providers/utility.provider';
import { IColumn } from '../shared/Table/types';

export const getEditorColumns = (columns): IColumn[] => [
  {
    headerName: 'PROVIDER',
    fieldName: 'name',
    fieldType: FilterDataTypeEnum.STRING
  },
  ...columns.map(c => {
    return {
      headerName: convertEnumToReadableString(c),
      fieldName: c,
      fieldType: FilterDataTypeEnum.NUMBER,
      editable: true,
      editType: 'number',
      emptyValue: 0
    };
  }),
  {
    headerName: 'Provider Total',
    fieldName: 'providerTotal',
    fieldType: FilterDataTypeEnum.NUMBER,
    editable: true,
    editType: 'number',
    emptyValue: 0
  },
  {
    headerName: '',
    fieldName: '',
    upperBoundError: 'Error: Exceeds total MPS',
    lowerBoundError: 'Unallocated MPS',
    fieldType: FilterDataTypeEnum.EDIT
  }
];