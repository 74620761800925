import { useAuth0 } from '@auth0/auth0-react';
import { Button } from './shared/Buttons/Button';
import { ButtonVariantEnum } from './shared/Buttons/types';
import LoadingIndicator from './shared/LoadingIndicator';

interface ILoginProps {
  authenticate: () => void;
  authenticateWithConsent: () => void;
  isConfigured: boolean;
}

const Login = ({ authenticate, authenticateWithConsent, isConfigured }: ILoginProps): JSX.Element => {
  const { isAuthenticated: isLoggedIn, isLoading: isAuth0Loading, loginWithRedirect } = useAuth0();

  if (isAuth0Loading) {
    console.log('Auth0: Loading');
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }

  if (!isLoggedIn) {
    loginWithRedirect();
    return <></>;
  }

  if (isLoggedIn && !isConfigured) {
    console.log('process.env.NX_LOCAL', process.env.NX_LOCAL);
    if (process.env.NX_LOCAL?.toLowerCase() === 'true') {
      // For security reasons, localhost needs to request consent
      // This can only be done via a click event
      // https://auth0.com/docs/get-started/applications/confidential-and-public-applications/user-consent-and-third-party-applications
      return (
        <div className="flex items-center justify-center w-screen h-screen text-white bg-slate-700">
          <Button
            variant={ButtonVariantEnum.SECONDARY}
            onClick={() => {
              authenticateWithConsent();
            }}
          >
            Consent (localhost only)
          </Button>
        </div>
      );
    }
  }
  return <></>;
};

export default Login;
