import { Icon } from './Icon';

export const Clients = () => (
  <Icon nav>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
      <g clipPath="url(#clip0_746_2668)">
        <path
          d="M6.99676 0.561646C9.10484 0.561646 10.812 2.26165 10.812 4.36089C10.812 6.46013 9.10484 8.16013 6.99676 8.16013C4.88868 8.16013 3.18152 6.46013 3.18152 4.36089C3.18152 2.26165 4.88868 0.561646 6.99676 0.561646ZM9.54457 4.36089C9.54457 2.96354 8.40646 1.82377 6.99676 1.82377C5.58706 1.82377 4.44896 2.9571 4.44896 4.36089C4.44896 5.76468 5.58706 6.89801 6.99676 6.89801C8.40646 6.89801 9.54457 5.76468 9.54457 4.36089Z"
          fill="currentColor"
        />
        <path
          d="M10.1783 9.43513H3.81524C1.70716 9.43513 0 11.1351 0 13.2344V17.5617H14V13.2344C14 11.1351 12.2928 9.43513 10.1848 9.43513H10.1783ZM12.7261 16.2931H1.2739V13.2344C1.2739 11.837 2.41201 10.6973 3.82171 10.6973H10.1848C11.588 10.6973 12.7326 11.8306 12.7326 13.2344V16.2931H12.7261Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_746_2668">
          <rect width="14" height="17" fill="white" transform="translate(0 0.561646)" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
