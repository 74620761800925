import { IHttpResponse, ISearchClientByTenantRequest, ISearchClientByTenantResponse } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClientListSearchByTenant(request: ISearchClientByTenantRequest): UseAxiosResult<IHttpResponse<ISearchClientByTenantResponse[]>> {
  const data = useAxios<any>({
    url: 'clients/search-by-tenant',
    method: 'POST',
    data: request
  }, { manual: true });
  return data;
}
