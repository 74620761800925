import { useAuth0 } from '@auth0/auth0-react';
import { configure } from 'axios-hooks';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { TokenResponse, configureAxiosWithAuth0 } from './authAxios';
import ClientDetails, { ClientDetailsViewEnum } from './components/Clients/ClientDetails';
import Clients from './components/Clients/Clients';
import { EditClient } from './components/Clients/EditClient';
import SaveClient from './components/Clients/SaveClient';
import Login from './components/Login';
import MPSCalculator from './components/MPS/MPSCalculator';
import MPSEditor from './components/MPS/MPSEditor';
import MPSTracker from './components/MPS/MPSTracker';
import OptOuts from './components/Optouts/Optouts';
import UploadOptIns from './components/Optouts/UploadOptins';
import UploadOptOuts from './components/Optouts/UploadOptouts';
import CreateTCRCampaigns from './components/TCRCampaigns/CreateTCRCampaigns';
import TCRCampaignDetails from './components/TCRCampaigns/TCRCampaignDetails';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { ProtectedRoute } from './components/shared/Auth/ProtectedRoute';
import { initializeWebSocket } from './contexts/SocketContext';
import { auth0Audience, auth0Scope } from './providers/auth0.service';

const App = (): JSX.Element => {
  const [isConfigured, setIsConfigured] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout: auth0Logout,
    user: auth0User,
  } = useAuth0();

  initializeWebSocket();

  async function getAccessToken() {
    const response = await getAccessTokenSilently({
      detailedResponse: true,
      audience: auth0Audience,
      ignoreCache: false,
      scope: auth0Scope,
    });

    const token: TokenResponse = {
      id_token: response.id_token,
      access_token: response.access_token,
      expires_in: response.expires_in,
      scope: response.scope,
    };

    await configureAxios(token);
  }

  async function authenticateWithConsent() {
    const authToken = await getAccessTokenWithPopup({ audience: auth0Audience, ignoreCache: true, scope: auth0Scope });

    const response: TokenResponse = {
      access_token: authToken,
      expires_in: 1000000,
    };

    await configureAxios(response);
  }

  async function configureAxios(token: TokenResponse) {
    const authAxios = configureAxiosWithAuth0({ token, auth0User, auth0Logout });

    configure({
      axios: authAxios,
      defaultOptions: { useCache: false },
    });

    setIsConfigured(true);
  }
  useEffect(() => {
    const auth = async () => {
      try {
        await getAccessToken();
      } catch (e) {
        console.error(e);
      }
    };
    if (isLoggedIn) {
      auth();
    }
  }, [isLoggedIn]);
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Login
            isConfigured={isConfigured}
            authenticate={getAccessToken}
            authenticateWithConsent={authenticateWithConsent}
          />
        }
      />
      <Route element={<ProtectedRoute />}>
        <Route element={<AppWrapper configured={isConfigured} />}>
          <Route element={<Navigate to="/mps-tracker" />} path="/" />
          <Route element={<MPSTracker />} path="/mps-tracker" />
          <Route element={<MPSCalculator />} path="/mps-calculator" />
          <Route element={<MPSEditor />} path="/mps-editor" />
          <Route element={<ClientDetails view={ClientDetailsViewEnum.CLIENT_INFO} />} path="/clients/:clientId" />
          <Route element={<ClientDetails view={ClientDetailsViewEnum.CLIENT_INFO} />} path="/clients/:clientId/info" />
          <Route element={<ClientDetails view={ClientDetailsViewEnum.BRAND_INFO} />} path="/clients/:clientId/brand" />
          <Route element={<ClientDetails view={ClientDetailsViewEnum.TCR_CAMPAIGNS} />} path="/clients/:clientId/tcr" />
          <Route element={<EditClient />} path="/clients/:clientId/edit" />
          <Route element={<TCRCampaignDetails />} path="/clients/:clientId/tcr/:tcrId" />
          <Route element={<CreateTCRCampaigns />} path="/clients/:clientId/tcr/add" />
          <Route element={<Clients />} path="/clients" />
          <Route element={<SaveClient />} path="/clients/add" />
          <Route element={<OptOuts />} path="/opt-outs" />
          <Route element={<UploadOptOuts />} path="/opt-outs/add" />
          <Route element={<UploadOptIns />} path="/opt-outs/opt-in" />
        </Route>
      </Route>
      <Route element={PageNotFound} />
    </Routes>
  );
};

export default App;
