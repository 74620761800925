import { FilterDataTypeEnum } from '@shared/models';
import { convertEnumToReadableString } from 'apps/gsd-portal/src/providers/utility.provider';
import format from 'date-fns/format';
import { useState } from 'react';
import { TextInput } from '../Form/TextInput';
import { Edit } from '../Icons/Edit';
import { IColumn } from './types';

interface ITableList {
  columns: IColumn[];
  items: any;
  onCellEdit: (event, index, column) => void;
  validateRow?: (row, index, setRowEditable) => void;
}



const TableList = ({ columns, items, onCellEdit, validateRow }: ITableList) => {
  const [rowEditable, setRowEditable] = useState<number>();
  const maxColLength = 45;

  const formatDataField = (item: any, col: IColumn, skipEnumFormattingColumns?, enumExceptions?, booleanDisplayValues?): string => {
    const content: string = item[col.fieldName] ?? '';
    switch (col.fieldType) {
      case FilterDataTypeEnum.STRING: {
        return content.length > maxColLength ? `${content.slice(0, maxColLength - 3)}...` : content;
      }
      case FilterDataTypeEnum.NUMBER: {
        return (content ? content.toLocaleString() : col.emptyValue) ?? '';
      }
      case FilterDataTypeEnum.DATE: {
        try {
          return format(new Date(content), 'Pp');
        } catch (error) {
          console.log('Unable to parse date', content);
          return col.emptyValue ?? '';
        }
      }
      case FilterDataTypeEnum.ENUM: {
        if (skipEnumFormattingColumns.find((val) => val === col.fieldName)) {
          return content;
        }
        const exception = enumExceptions.find((val) => val.fieldName === col.fieldName);
        if (exception && exception.exceptions.find((ex) => ex === content)) {
          return content.replace(/_/g, ' '); // Even when we don't want the readable string (abbreviations), we still don't want underscores
        }
        return convertEnumToReadableString(content);
      }
      case FilterDataTypeEnum.BOOLEAN: {
        const customDisplayValues = booleanDisplayValues?.find((val) => val?.fieldName === col?.fieldName);
        const displayValues = customDisplayValues?.displayValues || { trueLabel: 'Yes', falseLabel: 'No' };

        return content ? displayValues.trueLabel : displayValues.falseLabel;
      }
      default: {
        return content;
      }
    }
  };

  const generateRowForItem = (item: any, index: number, columns: IColumn[], dataClass?: string, compact?: boolean): JSX.Element[] => {
    const defaultDataClass: string = dataClass ?? `text-sm px-3 ${compact ? 'py-2' : 'py-4'} whitespace-nowrap`;

    const rowData = columns?.map((col: IColumn) => {
      // This allows for invalid html. Maybe don't allow custom rendering on tables, but allow for lists
      if (col.renderColumn) {
        return (
          <td
            key={`item_${col.fieldName}_${index}`}
            onClick={() => {
              col.onColumnClick && col.onColumnClick(item, index);
            }}
            className={`text-gray-500 dark:text-slate-400 ${defaultDataClass} ${col.onColumnClick &&
              `hover:underline text-sky-600 dark:text-slate-300 dark:hover:text-white font-medium cursor-pointer hover:text-sky-900`
              }`}
          >
            {col.renderColumn(item)}
          </td>
        );
      }

      if (col.isRowHeader) {
        if (col.onColumnClick) {
          return (
            <td
              key={`item_${col.fieldName}_${index}`}
              onClick={() => {
                col.onColumnClick && col.onColumnClick(item, index);
              }}
              className={`${defaultDataClass} hover:underline text-sky-600 dark:text-slate-300 dark:hover:text-white font-medium cursor-pointer hover:text-sky-900`}
            >
              {item[col.fieldName]}
            </td>
          );
        }
        return (
          <td
            key={`item_${col.fieldName}_${index}`}
            className={`${defaultDataClass} text-gray-900 dark:text-white font-medium`}
          >
            {item[col.fieldName]}
          </td>
        );
      }

      return (
        index !== rowEditable || (!col.editable && col.fieldType !== FilterDataTypeEnum.EDIT) ? <td key={`item_${col.fieldName}_${index}`} className={`text-gray-500 dark:text-slate-400 ${defaultDataClass}`}>
          {(col.fieldType !== FilterDataTypeEnum.EDIT) ? formatDataField(item, col) : <div onClick={() => rowEditable === undefined && setRowEditable(index)} className={`${rowEditable !== undefined ? 'text-medium-gray' : 'text-wc-blue cursor-pointer'}`}>{<Edit />}</div>}
        </td> : col.fieldType !== FilterDataTypeEnum.EDIT ? <td>
          <TextInput
            type={col.editType}
            name={`item_${col.fieldName}_${index}`}
            value={item[col.fieldName]}
            className={`${item.valid > 0 ? 'border-fire' : item.valid < 0 ? 'border-turquoise' : ''}`}
            onChange={(e) => onCellEdit(e, index, col)} />
        </td> : <td onClick={() => validateRow!(item, index, setRowEditable)} className='text-center border-r-4 cursor-pointer shadow-wc-blue text-wc-blue border-wc-blue'><h4>Validate</h4>{item.valid > 0 && <div className='pt-1 text-fire'>{col.upperBoundError}</div>}{item.valid < 0 && <div className='pt-1 text-turquoise'>{col.lowerBoundError}</div>}</td>
      );
    });

    return rowData || [];
  };
  return <table className='table-list'>
    <thead>
      <tr>
        {columns.map(column => {
          return <th key={column.headerName} className='table-list-header'>
            {column.headerName}
          </th>;
        })}
      </tr>
    </thead>
    <tbody className='table-list-body'>
      {items.map((item, index) => <>
        <tr key={`${index}`} className='table-list-row'>{generateRowForItem(item, index, columns)}</tr>
        <tr key={`${index}-space`} className='h-4'></tr>
      </>)}
    </tbody>
  </table>;
};

export default TableList;