import { LoadingIndicator, ToggleSwitch } from '@Wonder-Cave/ui';
import { FilterDataTypeEnum } from '@shared/models';
import { useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import TableCard from '../shared/Table/TableCard';
import { IColumn } from '../shared/Table/types';

const MPSTracker = () => {
  const [environments, setEnvironments] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState("");

  const getCapacity = async (showLoader: boolean) => {
    if (showLoader) {
      setLoading(true);
    }
    try {
      const response = await axiosPost('/mps-tracker-get-capacity');
      setEnvironments(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const toggleExtendedSendingWindow = async (environmentId, extendedSendingWindow) => {
    setToggleLoading(environmentId);
    try {
      await axiosPost('/toggle-extended-sending-window', {
        environmentId,
        extendedSendingWindow
      });
      await getCapacity(false);
    } catch (e) {
      console.error(e);
    } finally {
      setToggleLoading("");
    }
  };

  useEffect(() => {
    getCapacity(true);
  }, []);

  const getColumns = (sums, environment, providerEnvironment): IColumn[] => {
    return [
      {
        headerName: 'Provider',
        fieldName: 'providerName',
        fieldType: FilterDataTypeEnum.STRING,
        subHeader: () => environment
      },
      {
        headerName: 'SMS MPS',
        fieldName: 'smsMPS',
        fieldType: FilterDataTypeEnum.NUMBER,
        subHeader: () => sums.smsMPS.toLocaleString()
      },
      {
        headerName: 'SMS Remaining Capacity',
        fieldName: 'smsRemainingCapacity',
        fieldType: FilterDataTypeEnum.NUMBER,
        subHeader: () => sums.smsRemainingCapacity.toLocaleString()
      },
      {
        headerName: 'SMS Total Scheduled',
        fieldName: 'smsTotalScheduled',
        fieldType: FilterDataTypeEnum.NUMBER,
        subHeader: () => sums.smsTotalScheduled.toLocaleString()
      },
      {
        headerName: 'MMS MPS',
        fieldName: 'mmsMPS',
        fieldType: FilterDataTypeEnum.NUMBER,
        subHeader: () => sums.mmsMPS.toLocaleString()
      },
      {
        headerName: 'MMS Remaining Capacity',
        fieldName: 'mmsRemainingCapacity',
        fieldType: FilterDataTypeEnum.NUMBER,
        subHeader: () => sums.mmsRemainingCapacity.toLocaleString()
      },
      {
        headerName: 'MMS Total Scheduled',
        fieldName: 'mmsTotalScheduled',
        fieldType: FilterDataTypeEnum.NUMBER,
        subHeader: () => sums.mmsTotalScheduled.toLocaleString()
      },
      {
        headerName: 'Extended Sending Window',
        fieldName: 'extendedSendingWindow',
        fieldType: FilterDataTypeEnum.BOOLEAN,
        subHeader: (item) => {
          return <div className='flex items-center'><ToggleSwitch disabled={toggleLoading.length > 0} checked={providerEnvironment.extendedSendingWindow} onChange={() => {
            toggleExtendedSendingWindow(providerEnvironment.environmentId, !providerEnvironment.extendedSendingWindow);
          }} />{toggleLoading === providerEnvironment.environmentId && <LoadingIndicator className='ml-2' />}</div>;
        }
      }
    ];
  };

  return <div className='space-y-4 px-28'>
    <div className='flex items-center justify-between'>
      <h1 className='inline-block'>MPS Tracker</h1>
      <Button isLoading={loading} onClick={() => getCapacity(true)} variant={ButtonVariantEnum.SECONDARY}>REFRESH</Button>
    </div>
    {Object.keys(environments).length > 0 && Object.keys(environments).map((key, index) => <TableCard defaultOpen={true} columns={getColumns(
      environments[key].reduce((accumulator, current) => {
        accumulator.smsMPS += current.smsMPS;
        accumulator.smsRemainingCapacity += current.smsRemainingCapacity;
        accumulator.smsTotalScheduled += current.smsTotalScheduled;
        accumulator.mmsMPS += current.mmsMPS;
        accumulator.mmsRemainingCapacity += current.mmsRemainingCapacity;
        accumulator.mmsTotalScheduled += current.mmsTotalScheduled;
        return accumulator;
      }, {
        smsMPS: 0,
        smsRemainingCapacity: 0,
        smsTotalScheduled: 0,
        mmsMPS: 0,
        mmsRemainingCapacity: 0,
        mmsTotalScheduled: 0
      }),
      key,
      environments[key][0] // We only need the first provider here, the environmentId and timezoneRouting info is the same for all 
    )} items={environments[key]} />)}
  </div>;
};

export default MPSTracker;