import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface IErrorMessageProps {
  show: boolean;
  message: string | undefined;
}

const ErrorMessage = ({ show, message }: IErrorMessageProps): JSX.Element => {
  return (
    <>
      <Transition
        as={Fragment}
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <p className="mt-2 text-sm text-red-600 dark:text-red-400 dark:font-medium" id="error">
          {message}
        </p>
      </Transition>
    </>
  );
};

export { ErrorMessage };
