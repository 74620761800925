import { Button, ButtonVariantEnum, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { FastField, Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import GenericDropdown from '../../shared/Form/Dropdowns/GenericDropdown';
import { ITokenForm } from '../types';

interface ITokenProps {
  onSubmit: (data: ITokenForm) => void;
  onBack: () => void;
  partnerOptions: IDropdownValue[];
}

const ClientCompanyInfoForm = ({ onSubmit, partnerOptions, onBack }: ITokenProps) => {

  const [isLoading, setIsLoading] = useState(false);

  const schema: yup.SchemaOf<ITokenForm> = yup.object().shape({
    partnerToken: yup.string(),
    partnerId: yup.string(),
    partnerCode: yup.object()
      .shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().defined('Required'),
      })
      .nullable()
  });

  return (
    <Formik
      onSubmit={async (values) => {
        try {
          setIsLoading(true);
          await onSubmit(values);
        } finally {
          setIsLoading(false);
        }
      }}
      validationSchema={schema}
      initialValues={{
        partnerCode: { label: 'CV', value: "Campaign Verify" },
        partnerId: undefined,
        partnerToken: undefined
      }}>
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty }) => (
        <form id="token-form" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-8 token-container items-center mx-auto max-w-[50%]">
            <h2 className="text-black basis-full">Token</h2>
            <GenericDropdown
              label="Select Vetting Partner"
              placeholder="Make most used partner the default"
              options={partnerOptions}
              value={values.partnerCode}
              onChange={(newValue) => {
                setFieldValue('partnerCode', newValue);
                setFieldTouched('partnerCode');
              }}
              onBlur={() => {
                setFieldTouched('partnerCode');
              }}
              showError={!!(touched.partnerCode && errors.partnerCode)}
              errorMessage={(errors?.partnerCode as any)?.value as string}
            />

            <FastField
              component={TextInput}
              id="partnerId"
              name="partnerId"
              label="Vetting Id"
              placeholder="Enter vetting id"
              value={values.partnerId}
              error={touched.partnerId ? errors.partnerId : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {values.partnerCode.value === 'Aegis Mobile' &&
              <FastField
                component={TextInput}
                id="partnerToken"
                name="partnerToken"
                label="Verify Token"
                placeholder="Enter token number"
                value={values.partnerToken}
                error={touched.partnerToken ? errors.partnerToken : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }

            <div className="flex w-full mt-auto">
              <Button
                variant={ButtonVariantEnum.SECONDARY}
                className="mr-4"
                onClick={onBack}
              >
                BACK
              </Button>
              <Button
                isLoading={isLoading}
                variant={ButtonVariantEnum.SECONDARY}
                className="mr-4"
                formId="token-form"
                type="submit"
              >
                I'LL DO THIS LATER
              </Button>
              <Button isLoading={isLoading} formId="token-form" type="submit">
                COMPLETE
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ClientCompanyInfoForm;
