import { Static, StaticTableList } from '@Wonder-Cave/ui';
import { ISearchClientsRecord, TcrRegistryAppeal } from '@shared/models';
import { TcrRegistryVetting } from '@shared/models/domain/clients/tcr-vetting.interface';
import moment from 'moment';

interface BrandInformationProps {
  client?: ISearchClientsRecord;
}

export const BrandInformation = ({ client }: BrandInformationProps) => {
  return <div className='flex flex-col px-20'>
    <div className='flex flex-col'>
      <div className='flex flex-wrap items-center justify-between'>
        <h2 className='text-black'>Identity Status</h2>
      </div>
      <div className='flex flex-wrap mb-8 static-box'>
        <Static shimmer={10} title='IDENTITY STATUS'>{client?.tcrBrand?.identityStatus}</Static>
        <Static shimmer={10} title='RUSSELL 3000'>{client?.tcrBrand?.optionalAttributes?.russell3000 ?? 'N/A'}</Static>
        <Static shimmer={10} title='TAX EXEMPT STATUS'>{client?.tcrBrand?.optionalAttributes?.taxExemptStatus ?? 'N/A'}</Static>
        <Static shimmer={10} title='GOVERNMENT ENTITY'>{client?.tcrBrand?.optionalAttributes?.governmentEntity ?? 'N/A'}</Static>
      </div>
      <h4 className='mb-8 font-semibold text-black'>Appeal History</h4>
      <StaticTableList
        columns={[{
          headerName: 'VETTING STATUS',
          fieldName: 'appealOutcome.vetStatus',
          renderColumn: (item: TcrRegistryAppeal) => <>{item.appealOutcome?.vetStatus ? item.appealOutcome?.vetStatus : 'N/A'}</>
        }, {
          headerName: 'REQUEST DATE',
          fieldName: 'createDate',
          renderColumn: (item: TcrRegistryAppeal) => <>{item.createDate ? moment(item.createDate).format('L') : undefined}</>
        }, {
          headerName: 'STATUS UPDATE DATE',
          fieldName: 'appealStatusUpdateDate',
          renderColumn: (item: TcrRegistryAppeal) => <>{item.appealStatusUpdateDate ? moment(item.appealStatusUpdateDate).format('L') : undefined}</>
        }, {
          headerName: 'DETAILS',
          fieldName: 'explanation'
        }]}
        data={client?.tcrAppeals ?? []}
      />
      {/* <div className='flex items-center mt-12'>
        <h2 className='text-black'>Brand Suspended</h2>
      </div>
      <div className='flex flex-wrap static-box'>
        <Static shimmer={10} title='AT&T'>{ }</Static>
        <Static shimmer={10} title='T-MOBILE'>{ }</Static>
        <Static shimmer={10} title='VERIZON WIRELESS'>{ }</Static>
        <Static shimmer={10} title='US CELLULAR'>{ }</Static>
        <Static shimmer={10} title='CLEARSKY'>{ }</Static>
        <Static shimmer={10} title='INTEROP'>{ }</Static>
      </div> */}
      <div className='flex items-center mt-12 mb-8'>
        <h2 className='text-black'>Vetting Details</h2>
      </div>
      <StaticTableList
        columns={[{
          headerName: 'VETTING TYPE',
          fieldName: 'vettingClass'
        }, {
          headerName: 'VETTING PARTNER',
          fieldName: 'evpId',
        }, {
          headerName: 'STATUS',
          fieldName: 'vettingStatus',
        }, {
          headerName: 'DATE',
          fieldName: 'vettedDate',
          renderColumn: (item: TcrRegistryVetting) => <>{item.vettedDate ? moment(item.vettedDate).format('L') : undefined}</>
        },
        {
          headerName: 'OUTCOME',
          fieldName: 'reasons',
          renderColumn: (item: TcrRegistryVetting) => <>{item.reasons ? item.reasons.join(', ') : 'N/A'}</> // in prod reasons have returned as null need to account for this possibility
        }]}
        data={client?.tcrVetting ?? []}
      />
    </div>
  </div>;
};