export enum TcrUsecaseEnum {
  AGENTS_FRANCHISES = "AGENTS_FRANCHISES",
  CARRIER_EXEMPT = "CARRIER_EXEMPT",
  CHARITY = "CHARITY",
  EMERGENCY = "EMERGENCY",
  K12_EDUCATION = "K12_EDUCATION",
  LOW_VOLUME = "LOW_VOLUME",
  M2M = "M2M",
  MIXED = "MIXED",
  POLITICAL = "POLITICAL",
  PROXY = "PROXY",
  SOCIAL = "SOCIAL",
  SOLE_PROPRIETOR = "SOLE_PROPRIETOR",
  SWEEPSTAKE = "SWEEPSTAKE",
  TRIAL = "TRIAL",
  UCAAS_HIGH = "UCAAS_HIGH",
  UCAAS_LOW = "UCAAS_LOW",
}