import { IColumn, IColumnClickConfig } from '@Wonder-Cave/ui';
import { FilterDataTypeEnum, ISearchRequest, ISearchTcrCampaignsRecord, SearchSortDirectionEnum } from '@shared/models';
import { IDropdownValue } from '../shared/Form/Dropdown';

export const tcrCampaignTableTake = 10;

export const defaultTcrCampaignTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: tcrCampaignTableTake,
  },
  sort: {
    fieldName: 'providerId',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export interface IColumnRenderConfig {
  [key: string]: (item?: any) => JSX.Element;
}

export interface ITcrCampaignItem extends ISearchTcrCampaignsRecord {
  isLoading?: boolean;
}

export function getColumns(clickEvents: IColumnClickConfig, renders: IColumnRenderConfig): IColumn[] {
  return [
    {
      headerName: 'Title',
      fieldName: 'description',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: clickEvents?.title
    },
    {
      headerName: 'Stage',
      fieldName: 'tcrCampaign.stage',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: renders?.stage
    },
    {
      headerName: 'TCR ID',
      fieldName: 'tcrCampaignId',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Status',
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
      renderColumn: renders?.isActive
    },
    {
      headerName: 'Provider',
      fieldName: 'provider.name',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: renders?.provider
    },
    {
      headerName: 'Campaign',
      fieldName: 'campaignType',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Type',
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Count', // number of phone numbers
      fieldName: 'phoneNumberCount',
      fieldType: FilterDataTypeEnum.NUMBER,
    },
  ];
};

export const usCountryOption: IDropdownValue = { value: 'US', label: 'United States of America' };
export const countryOptions: IDropdownValue[] = [usCountryOption];