import { HttpRequestSchema } from '@shared/services';
import { object, string } from 'yup';

export interface IProviderGetAllRequest {
  environmentId: string;
}

export const IProviderGetAllRequestSchema = object<HttpRequestSchema<IProviderGetAllRequest>>({
  environmentId: string().required(),
});
