import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '../Dropdown';

interface IGenericDropdownProps {
  label: string;
  placeholder?: string;
  loading?: boolean;
  options: IDropdownValue[];
  value?: IDropdownValue | undefined;
  rawValue?: string;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: (e?: any) => any;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const GenericDropdown = ({
  label,
  options,
  placeholder,
  value,
  loading,
  rawValue,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IGenericDropdownProps): JSX.Element => {
  return (
    <Autocomplete
      label={label}
      placeholder={placeholder}
      value={options.find((o) => o.value === value?.value)}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      loading={loading}
      full
      gray={disabled}
    />
  );
};

export default GenericDropdown;
