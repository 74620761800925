import { TcrSubUsecaseEnum } from './tcr-sub-usecase.enum';
import { TcrUsecaseEnum } from './tcr-usecase.enum';

export enum TcrRegistryCampaignStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface TcrRegistryCampaign {
  campaignId: string;
  cspId: string;
  resellerId: string;
  status: TcrRegistryCampaignStatus;
  createDate: string;
  autoRenewal: boolean;
  billedDate: string;
  brandId: string;
  usecase: TcrUsecaseEnum;
  subUsecases: TcrSubUsecaseEnum[];
  description: string;
  embeddedLink: boolean;
  embeddedPhone: boolean;
  termsAndConditions: boolean;
  numberPool: boolean;
  ageGated: boolean;
  directLending: boolean;
  subscriberOptin: boolean;
  subscriberOptout: boolean;
  subscriberHelp: boolean;
  sample1: string;
  sample2: string;
  sample3: string;
  sample4: string;
  sample5: string;
  messageFlow: string;
  helpMessage: string;
  referenceId: string;
  mock: boolean;
  nextRenewalOrExpirationDate: string;
  expirationDate: string;
  optinKeywords: string; // comma-separated
  optoutKeywords: string; // comma-separated
  helpKeywords: string; // comma-separated
  optinMessage: string;
  optoutMessage: string;
  monthlyFee: number;
}