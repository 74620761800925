import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { GRAuth0OrganizationID, GREnvironment } from '@shared/models';
import useClientListSearchByTenant from 'apps/gsd-portal/src/hooks/useClientSearchByTenant';
import { sortByLabel } from 'apps/gsd-portal/src/providers/utility.provider';
import { useEffect, useState } from 'react';

interface IClientSearchByTenantDropdownProps {
  value: string;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  environment: GREnvironment;
  orgId: GRAuth0OrganizationID;
}
const ClientDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  environment,
  orgId }: IClientSearchByTenantDropdownProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [{ data: clientData, loading: clientDataLoading, error: clientSearchError }, searchClientByTenant] = useClientListSearchByTenant({ environment, orgId, name: searchText });

  useEffect(() => {
    if (environment && searchText.length > 0) {
      try {
        searchClientByTenant();
      } catch (e) {
        console.log(e);
      }
    }
  }, [environment, searchText]);

  const clients: IDropdownValue[] = sortByLabel(
    clientData?.map((client) => {
      return {
        label: client.name ?? '',
        value: client.id ?? '',
      };
    }) ?? []
  );

  const handleSearchClients = async (searchValue: string) => {
    setSearchText(searchValue);
  };

  return (
    <Autocomplete
      label="Select Clients"
      placeholder='Type to search for a client'
      debounceTime={300}
      search
      value={clients.find((cg) => cg.value === value)}
      options={clients}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchClients}
      loading={clientDataLoading}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      gray={disabled}
    />
  );
};

export default ClientDropdown;