import { IClickerGroupEntity, IHttpResponse, IHttpSearchResponse, ISearchClickerGroupsRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClickerGroups(request: ISearchClickerGroupsRequest): UseAxiosResult<IHttpResponse<IHttpSearchResponse<IClickerGroupEntity>>> {
  return useAxios<IHttpResponse<IHttpSearchResponse<IClickerGroupEntity>>>({
    url: 'clicker-groups',
    method: 'GET',
    params: request
  }, { manual: true });
}
