import { TableList, TextInput } from '@Wonder-Cave/ui';
import { SearchIcon } from '@heroicons/react/outline';
import { FilterDataTypeEnum, FilterOperatorEnum, IHttpSearchResponse, IOptoutInterface, IOptoutSearchResponseInterface, ISearchRequest } from '@shared/models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPost } from '../../authAxios';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { defaultOptoutTableOptions, getColumns } from './types';

const OptOuts = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [tableOptions, setTableOptions] = useState(defaultOptoutTableOptions);
  const [optoutData, setOptoutData] = useState<any[]>();
  const [optOutTotalCount, setOptOutTotalCount] = useState<number>(0);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<string>();


  const columns = getColumns({
    phoneNumber: (item: IOptoutInterface) => {
      const numberAsString = item?.phoneNumber?.toString() ?? '';
      return (
        <span className="font-medium text-gray-900 dark:text-white">{`(${numberAsString.substring(
          0,
          3
        )}) ${numberAsString.substring(3, 6)}-${numberAsString.substring(6)}`}</span>
      );
    },
    tenantsAndClients: (item: any) => {
      console.log('tenantsAndClients', item.tenantClientData);
      return (
        <div className='flex flex-inline w-96'>
          {item.tenantClientData.map((item) => {
            return (
              <div className='flex-col w-40 mr-4'>
                <div className='text-xs text-gray-500'>{item.tenant}</div>
                <div className='text-sm mt-1 overflow-hidden truncate'>{item.clients.toString().replace(',', ', ')}</div>
              </div>
            );
          })}


        </div>
      );
    },
  });

  const handlefetch = async (searchOptions: ISearchRequest) => {
    try {
      setFetchLoading(true);
      const response = await axiosPost<IHttpSearchResponse<IOptoutSearchResponseInterface>>('/optouts/search', searchOptions);
      setOptoutData(response.data.records);
      setOptOutTotalCount(response.data.totalCount);
    } catch (e: any) {
      setSearchError(e.message);
      console.log(e);
    }
    finally {
      setFetchLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.pagination.skip = page * 10;
    newSearchOptions.pagination.take = 10;
    handlefetch(newSearchOptions);
  };

  const handleSearch = async (searchOptions: ISearchRequest) => {
    const sanitizedSearchText = searchText.replace(/[()-\s]/g, '');
    let searchFilter = {
      dataType: FilterDataTypeEnum.NUMBER,
      fieldName: 'phoneNumber',
      operator: FilterOperatorEnum.CONTAINS,
      value: sanitizedSearchText,
    };

    const newSearchOptions = { ...searchOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'phoneNumber');

    newSearchOptions.filters.push(searchFilter);

    newSearchOptions.pagination = defaultOptoutTableOptions.pagination;

    setTableOptions(newSearchOptions);
    await handlefetch(newSearchOptions);
  };


  return (
    <>
      <div className="flex pb-2 px-28">
        <h1 id="clients-title" className="flex justify-between pb-2">
          Opt-outs
        </h1>
        <div className="ml-auto flex">
          <TextInput
            id="phoneLookup"
            name="phoneLookup"
            placeholder="Phone number"
            search
            value={searchText}
            onChange={(e) =>
              setSearchText(e.target.value)}
            onEnter={() => handleSearch(defaultOptoutTableOptions)}
            leadingIcon={<SearchIcon />}
            clearable
          />
          <Button
            id="opt-in"
            variant={ButtonVariantEnum.PRIMARY}
            // disabled={}
            className='ml-4'
            onClick={() => {
              navigate('/opt-outs/opt-in');
            }}
          >
            Opt-in
          </Button>
          <Button
            id="upload"
            variant={ButtonVariantEnum.TERTIARY}
            // disabled={}
            className='ml-4'
            onClick={() => {
              navigate('/opt-outs/add');
            }}
          >
            Upload
          </Button>
        </div>
      </div>
      <div className='pb-5 px-28 h-5/6 overflow-y-auto w-full'>
        {optoutData || fetchLoading ? <TableList
          loading={fetchLoading}
          shimmer
          columns={columns}
          items={optoutData ?? []}
          totalCount={optOutTotalCount ?? 0}
          limit={10}
          paginate
          onPaginate={(page) => handlePagination(page)}
          cssOverride={{
            'table-list': `!px-0`,
            'table-list-header': `!pl-4`
          }}
        /> : <div className='px-28 mt-[25%] text-center font-bold'>Search for a phone number using search bar (above) to view opt-out information.</div>}

      </div>
    </>
  );
};

export default OptOuts;