export enum CampaignTypeEnum {
  FUNDRAISING = 'FUNDRAISING',
  GOTV = 'GOTV',
  PERSUASION = 'PERSUASION',
  LIST_BUILDING = 'LIST_BUILDING',
}

export enum TCRMessageTypeEnum {
  OPTIN,
  OPTOUT,
  HELP
}
