import { useState } from 'react';
import { Dropdown as DropdownIcon } from '../Icons/Dropdown';
import { generateRowForItem } from './Table';
import { IColumn } from './types';

interface ITableCard {
  columns: IColumn[];
  items: any;
  defaultOpen?: boolean;
}

const TableCard = ({ columns, items, defaultOpen }: ITableCard) => {
  const [open, setOpen] = useState(defaultOpen ?? false);
  return <table className='table-card'>
    <thead>
      <tr>
        {columns.map(column => {
          return <th className='table-card-header'>
            {column.headerName}
          </th>;
        })}
      </tr>
    </thead>
    <tbody className='table-card-body'>
      {columns.some(c => !!c.subHeader) && <tr className='table-card-subheader'>
        {columns.map((column, index) => {
          return <td className={`${index === 0 && !open ? 'rounded-bl-2.5xl' : ''} ${index === 0 ? 'flex' : ''}`}><span>{column.subHeader!(items)}</span>{index === 0 && <span onClick={() => setOpen(prev => !prev)} className={`ml-2 mt-0.5 cursor-pointer ${open ? 'rotate-180' : ''}`}><DropdownIcon /></span>}</td>;
        })}
      </tr>}
      {open && items.map((item, index) => <tr className='table-card-row'>{generateRowForItem(item, index, columns)}</tr>)}
    </tbody>
  </table>;
};

export default TableCard;