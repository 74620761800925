import { Modal, TextInput } from '@Wonder-Cave/ui';
import { SearchIcon } from '@heroicons/react/solid';
import { paginateRecords, range, resize } from '@shared/services';
import { useState } from 'react';
import { formatPhoneNumber } from './types';

interface NumbersModalProps {
  show: boolean;
  title: string;
  phoneNumbers: string[];
  onClose: () => void;
}

const NumbersModal = ({ show, title, phoneNumbers, onClose }: NumbersModalProps) => {
  const MAX_PAGE_SIZE = 27;

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const getFilteredPhoneNumbers = (searchText: string | undefined, phoneNumbers: string[]) => {
    return !!searchText
      ? phoneNumbers?.filter(phoneNumber => phoneNumber?.toLowerCase()?.trim()?.includes(searchText?.toLowerCase()?.trim())) ?? []
      : phoneNumbers;
  };

  const getPages = (phoneNumbers: string[]) => {
    const filtered = getFilteredPhoneNumbers(searchText, phoneNumbers);

    return range(0, Math.ceil((filtered?.length ?? 0) / MAX_PAGE_SIZE));
  };

  const getPhoneNumbers = (phoneNumbers: string[], searchText: string | undefined, page: number) => {
    const filtered = getFilteredPhoneNumbers(searchText, phoneNumbers);

    const paginated = paginateRecords(filtered ?? [], {
      skip: page * MAX_PAGE_SIZE,
      take: MAX_PAGE_SIZE
    });

    const filled = resize(paginated, MAX_PAGE_SIZE) as string[];

    return filled?.map(phoneNumber => formatPhoneNumber(phoneNumber)) ?? [];
  };

  const handleClear = () => {
    setCurrentPage(0);
    setSearchText(undefined);
  };

  const handleSearch = (searchText?: string) => {
    setCurrentPage(0);
    setSearchText(searchText);
  };

  const pages = getPages(phoneNumbers);
  const formattedPhoneNumbers = getPhoneNumbers(phoneNumbers, searchText, currentPage);

  const titleComponent = <div className='flex flex-row justify-between items-center mt-4 mb-4'>
    <h2 className='w-full'>{title}</h2>
    <TextInput
      className='w-full'
      id="phoneSearch"
      name="phoneSearch"
      placeholder="Phone Number"
      search
      value={searchText}
      onChange={(event) => handleSearch(event.target.value)}
      onClear={handleClear}
      onEnter={() => handleSearch(searchText)}
      leadingIcon={<SearchIcon />}
      clearable
    />
  </div>;

  return (
    <Modal
      title={titleComponent}
      show={show}
      onClose={() => {
        handleClear();
        onClose();
      }}
      titleClassName='pl-8 text-black'
      childrenClassName='pl-8 pb-2 pt-2'
    >
      <div className="flex flex-row flex-wrap justify-between">
        {formattedPhoneNumbers?.map((phoneNumber, index) =>
          <div className='flex-1 basis-1/3'>
            <div className={`mt-3 mb-3 text-center text-dark-gray ${phoneNumber ? '' : 'opacity-0'} `}>
              {phoneNumber ? phoneNumber : 'N/A'}
            </div>
            <div className='flex-1 basis-1 w-full border-1 border-t border-mystic'></div>
          </div>
        )}
      </div>

      <div className="mt-8 flex flex-row flex-wrap align-middle justify-center ">
        <div className='flex justify-between space-x-2'>
          {pages?.map((page, index) =>
            <button
              disabled={page === currentPage}
              onClick={() => setCurrentPage(page)}
              className={`px-4 py-1 rounded-lg ${page === currentPage ? "bg-wc-blue text-white" : "bg-mystic text-black cursor-pointer"}`}
            >
              {page + 1}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NumbersModal;
