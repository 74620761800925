import { EntityName } from '@shared/enums';
import { FilterDataTypeEnum, FilterOperatorEnum, IExportRequest, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import { AnySchema, array, mixed, number, object, string } from 'yup';

const entity = mixed<EntityName>().oneOf(Object.values(EntityName));
const sortDirection = mixed<SearchSortDirectionEnum>().oneOf(Object.values(SearchSortDirectionEnum));
const dataType = mixed<FilterDataTypeEnum>().oneOf(Object.values(FilterDataTypeEnum));
const operator = mixed<FilterOperatorEnum>().oneOf(Object.values(FilterOperatorEnum));

const sort = object({
  fieldName: string().trim().required(),
  sortDirection: sortDirection.required()
});

const filter = object({
  fieldName: string().trim().required().matches(columnNameRegex()),
  dataType: dataType.required(),
  operator: operator.required(),
  value: mixed().required()
});

const pagination = object({
  skip: number().required().min(0),
  take: number().required().min(0)
});

const column = object({
  displayName: string().required(),
  columnName: string().required().matches(columnNameRegex())
});

export function validateSearchEntitiesRequest(request: ISearchRequest) {
  console.log('Validating Search Request', request);

  return validateObject(request, object({
    entity: entity.required(),
    filters: array().of(filter).optional(),
    sort: sort.required(),
    pagination: pagination.required()
  }));
}

export function validateExportEntitiesRequest(request: IExportRequest) {
  console.log('Validating Export Request', request);

  return validateObject(request, object({
    entity: entity.required(),
    filters: array().of(filter).optional(),
    sort: sort.required(),
    pagination: pagination.required(),
    userEmail: string().email().required(),
    fileName: string().required(),
    columns: array().of(column).required(),
  }));
}

function validateObject<T>(request: T, schema: AnySchema): T {
  return schema.validateSync(request, {
    strict: true,
    abortEarly: true,
    stripUnknown: true
  });
}

function columnNameRegex() {
  return /^[a-z\d\-_]+$/i;
}
