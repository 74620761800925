import { Auth0Organization, Auth0OrganizationAppID, Auth0OrganizationID } from '@shared/models';

export enum SubDomain {
  LOCAL = 'localhost:4200',
  GSD_DEV = 'dev',
  GSD_STAGING = 'staging',
  GSD = 'gsd'
}

export interface Auth0Config {
  label: string;
  clientId: Auth0OrganizationAppID;
  organization: Auth0Organization;
  organizationId: Auth0OrganizationID;
  apiUrl: string;
  websocketUrl: string;
  redirectUrl: string;
}

export function getSubDomainFromOrg(organization?: Auth0Organization) {
  return Object.values(SubDomain).find((subDomain) => organization?.endsWith(subDomain)) ?? undefined;
}

export function getOrganizationLabel(organization: Auth0Organization) {
  return getConfigByDomain(getSubDomainFromOrg(organization)).label;
}

export function getSubDomainLabel(domain?: SubDomain) {
  return getConfigByDomain(domain).label;
}

export function getAuth0Organization() {
  return getConfigByDomain().organization;
}

export function getAuth0Config() {
  return getConfigByDomain();
}

export function getWebSocketUrl() {
  return getConfigByDomain().websocketUrl;
}

export function getApiUrl() {
  return getConfigByDomain().apiUrl;
}

export function goToSubDomain(domain: SubDomain) {
  const url = Auth0ConfigMap.get(domain)?.redirectUrl;
  window.location.replace(url + window?.location?.pathname);
}

export function getSubDomain() {
  return <SubDomain>window?.location?.host?.split('.')?.[0];
}

function getConfigByDomain(domain?: SubDomain) {
  return <Auth0Config>Auth0ConfigMap.get(domain ?? getSubDomain());
}

// TODO: Move this to Config DB
const Auth0ConfigMap = createAuth0ConfigMap();

function createAuth0ConfigMap() {
  const map: Map<SubDomain, Auth0Config> = new Map();

  map.set(SubDomain.GSD_DEV, {
    label: 'DEV',
    clientId: Auth0OrganizationAppID.GSD_DEV,
    organization: Auth0Organization.GSD_DEV,
    organizationId: Auth0OrganizationID.GSD_DEV,
    apiUrl: `https://api.dev.gsd.wondercave.co`,
    websocketUrl: 'wss://ws.dev.gsd.wondercave.co',
    redirectUrl: `https://dev.gsd.wondercave.co`,
  });

  map.set(SubDomain.GSD_STAGING, {
    label: 'STAGING',
    clientId: Auth0OrganizationAppID.GSD_STAGING,
    organization: Auth0Organization.GSD_STAGING,
    organizationId: Auth0OrganizationID.GSD_STAGING,
    apiUrl: `https://api.staging.gsd.wondercave.co`,
    websocketUrl: 'wss://ws.staging.gsd.wondercave.co',
    redirectUrl: `https://staging.gsd.wondercave.co`,
  });

  map.set(SubDomain.GSD, {
    label: 'GSD',
    clientId: Auth0OrganizationAppID.GSD,
    organization: Auth0Organization.GSD,
    organizationId: Auth0OrganizationID.GSD,
    apiUrl: `https://api.gsd.wondercave.co`,
    websocketUrl: 'wss://ws.gsd.wondercave.co',
    redirectUrl: `https://gsd.wondercave.co`,
  });

  map.set(SubDomain.LOCAL, {
    ...(<Auth0Config>map.get(SubDomain.GSD_DEV)),
    apiUrl: `http://localhost:3000/dev`,
    websocketUrl: 'ws://localhost:3001',
    redirectUrl: 'http://localhost:4200',
  });

  return map;
}
