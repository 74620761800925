import { Button, ButtonVariantEnum, RadioCheckbox, RadioCheckedTurquoise } from '@Wonder-Cave/ui';
import { MessageTypeEnum } from '@shared/enums';
import { CampaignTypeEnum } from '@shared/enums/campaign-type-enum';
import { CreateTCRCampaignRequest, GRTcrCampaign } from '@shared/models';
import { TcrSubUsecaseEnum } from '@shared/models/domain/clients/tcr-sub-usecase.enum';
import { TcrUsecaseEnum } from '@shared/models/domain/clients/tcr-usecase.enum';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useProviders from '../../hooks/useProviders';
import useTcrUsecases from '../../hooks/useTcrUseCases';
import { convertEnumToReadableString } from '../../providers/utility.provider';
import { IDropdownValue } from '../shared/Form/Dropdown';
import GenericDropdown from '../shared/Form/Dropdowns/GenericDropdown';

interface Provider {
  name: string;
  id: string;
}

interface ITCRCampaignsProps {
  clientName: string;
  clientId: string;
}

const CreateTCRCampaigns = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useParams();
  const { addNotification } = useNotifications();
  const [{ data: providers, loading: providersLoading, error: providersError }, refetch] = useProviders();
  const [{ data: tcrUsecaseData, loading: usecasesLoading, error: usecasesError }, refetchUseCases] = useTcrUsecases();

  let usecaseOptions: IDropdownValue[] = tcrUsecaseData?.usecases.map(entry => ({ label: entry.displayName, value: entry.name })) ?? [];
  // if brand vertical is not political remove from usecaseOptions
  if (location.state.brandVertical.toLowerCase() !== 'political')
    usecaseOptions = usecaseOptions.filter((option) => option.value !== TcrUsecaseEnum.POLITICAL);

  const subUsecaseOptions: IDropdownValue[] = tcrUsecaseData?.subUsecases.map(entry => ({ label: entry.displayName, value: entry.name })) ?? [];
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedCampaignTypes, setSelectedCampaignTypes] = useState<CampaignTypeEnum[]>([]);
  const [selectedMessageTypes, setSelectedMessageTypes] = useState<MessageTypeEnum[]>([]);
  const [selectedUsecase, setSelectedUsecase] = useState<TcrUsecaseEnum>();
  const [selectedSubUsecase, setSelectedSubUsecase] = useState<TcrSubUsecaseEnum>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state.brandVertical && location.state.brandVertical.toLowerCase() === 'political') {
      setSelectedUsecase(TcrUsecaseEnum.POLITICAL);
      setSelectedSubUsecase(TcrSubUsecaseEnum.POLLING_VOTING);
    }
  }, []);

  const campaignTypes = Object.values(CampaignTypeEnum);
  const messageTypes = Object.values(MessageTypeEnum);
  const numCampaigns = selectedProviders.length * selectedCampaignTypes.length * selectedMessageTypes.length;
  const createTCRCampaigns = async () => {
    try {
      // ensure all required fields are populated. Due to disable logic on save button error below should never occur however including for safety.
      if (!selectedUsecase || !selectedSubUsecase) throw new Error('Usecase and Sub-usecase fields are required to create a TCR campaign.');
      setLoading(true);
      const response = await axiosPost<GRTcrCampaign[], AxiosResponse<GRTcrCampaign[]>, CreateTCRCampaignRequest>('/tcr-campaigns', {
        providerIds: selectedProviders,
        clientId: clientId!,
        campaignTypes: selectedCampaignTypes,
        messageTypes: selectedMessageTypes,
        usecase: selectedUsecase,
        subUsecases: [selectedSubUsecase]
      });
      addNotification({ content: `Successfully created ${response.data.length} of ${numCampaigns} campaigns.`, type: NotificationType.SUCCESS });
      navigate(`/clients/${clientId}/tcr`);
    } catch (error: any) {
      console.log(error.response);
      addNotification({ content: `Failed to create TCR campaigns with error: ${error.response.data?.message ?? error.response.data}`, type: NotificationType.FAILURE });
    } finally {
      setLoading(false);
    }
  };
  const filteredProviders = providers?.filter(p => p.name.includes('10DLC'));
  return <div className='flex flex-col h-full px-28 '>
    <h1 className='w-full mb-4'>Create TCR Campaigns</h1>
    <h3 className='w-full mb-20 text-wc-blue'>Client: {location?.state?.clientName}</h3>
    <h3 className='w-full mb-8 font-medium text-dark-gray'>Select at least one or more of each campaign type to create a TCR campaign:</h3>
    <div className='flex w-full pb-10 border-b-4 border-white'>
      <div className="flex flex-col pr-4 mt-4 space-y-4 basis-1/3 ">
        <div className="flex justify-between text-black">
          <h3>Provider</h3>
          <h3 className="font-medium text-medium-gray">{`(${selectedProviders.length}/${filteredProviders?.length})`}</h3>
        </div>
        {filteredProviders?.map((provider) => <RadioCheckbox
          key={provider.id}
          label={provider.name}
          checked={selectedProviders.includes(provider.id)}
          checkedIcon={<RadioCheckedTurquoise />}
          onChange={() => setSelectedProviders((prevState) => {
            const _providers = [...prevState];
            const index = _providers.indexOf(provider.id);
            if (index > -1) {
              _providers.splice(index, 1);
            } else {
              return [..._providers, provider.id];
            }
            return _providers;
          })}
        />)}
      </div>
      <div className="flex flex-col px-4 mt-4 space-y-4 basis-1/3">
        <div className="flex justify-between text-black">
          <h3>Campaign Type</h3>
          <h3 className="font-medium text-medium-gray">{`(${selectedCampaignTypes.length}/${campaignTypes.length})`}</h3>
        </div>
        {campaignTypes.map((campaignType) => <RadioCheckbox
          key={campaignType}
          label={campaignType === CampaignTypeEnum.GOTV ? 'GOTV' : convertEnumToReadableString(campaignType)}
          checked={selectedCampaignTypes.includes(campaignType)}
          checkedIcon={<RadioCheckedTurquoise />}
          onChange={() => setSelectedCampaignTypes((prevState) => {
            const _campaignTypes = [...prevState];
            const index = _campaignTypes.indexOf(campaignType);
            if (index > -1) {
              _campaignTypes.splice(index, 1);
            } else {
              return [..._campaignTypes, campaignType];
            }
            return _campaignTypes;
          })}
        />)}
      </div>
      <div className="flex flex-col pl-4 mt-4 space-y-4 basis-1/3">
        <div className="flex justify-between text-black">
          <h3>Message Type</h3>
          <h3 className="font-medium text-medium-gray">{`(${selectedMessageTypes.length}/${messageTypes.length})`}</h3>
        </div>
        {messageTypes.map((messageType) => <RadioCheckbox
          key={messageType}
          label={messageType}
          checked={selectedMessageTypes.includes(messageType)}
          checkedIcon={<RadioCheckedTurquoise />}
          onChange={() => setSelectedMessageTypes((prevState) => {
            const _messageTypes = [...prevState];
            const index = _messageTypes.indexOf(messageType);
            if (index > -1) {
              _messageTypes.splice(index, 1);
            } else {
              return [..._messageTypes, messageType];
            }
            return _messageTypes;
          })}
        />)}
      </div>
    </div>
    <div className='flex justify-between w-full mt-10 mb-auto'>
      <div className='w-[35%]'>
        <GenericDropdown disabled={location.state.brandVertical.toLowerCase() === 'political'} placeholder='Select TCR Use Case' label='TCR Use Case' options={usecaseOptions} value={usecaseOptions.find((option => option.value === selectedUsecase))} onChange={(e) => setSelectedUsecase(e?.value)} />
      </div>
      <div className='w-[35%]'>
        <GenericDropdown placeholder='Select Sub-Use Case' label='TCR Sub-use CASE' options={subUsecaseOptions} value={subUsecaseOptions.find((option => option.value === selectedSubUsecase))} onChange={(e) => setSelectedSubUsecase(e?.value)} />
      </div>
    </div>
    <div className='flex items-center justify-between w-full mt-auto mb-8'>
      <h3 className='text-wc-blue'>Based on your selection, {numCampaigns} TCR Campaigns will be created for {location?.state?.clientName}</h3>
      <div>
        <Button className='mr-4' type="button" onClick={() => navigate(-1)} variant={ButtonVariantEnum.SECONDARY}>BACK</Button>
        <Button isLoading={loading} type="button" disabled={numCampaigns <= 0 || (!selectedUsecase || !selectedSubUsecase)} onClick={() => createTCRCampaigns()} variant={ButtonVariantEnum.PRIMARY}>SAVE</Button>
      </div>
    </div>
  </div>;
};

export default CreateTCRCampaigns;
