export enum ButtonVariantEnum {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  DEFAULT,
  TEXT_PRIMARY,
  TEXT_DEFAULT,
  DELETE,
  SUCCESS,
  TAG,
  LINK_TAG
}
