import { Auth0Role } from '@shared/models';
import { useMembership } from '../../contexts/MembershipContext';
import useRoles from '../../hooks/useRoles';
import { PageNotFound } from './Auth/PageNotFound';
import AuthorizedShimmer from './AuthorizedShimmer';

interface IRolesProps {
  roles: Auth0Role[];
  children: JSX.Element;
  showPageNotFound?: boolean;
}

const Roles = ({ roles, children, showPageNotFound }: IRolesProps): JSX.Element | null => {
  const { isLoaded } = useMembership();
  const allowed = useRoles(roles);
  if (allowed) {
    return <>{children}</>;
  } else if (!isLoaded) {
    return <AuthorizedShimmer />;
  } else if (showPageNotFound) {
    return <PageNotFound />;
  } else {
    return <></>;
  }
};

export default Roles;
