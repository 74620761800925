import { Link } from 'react-router-dom';
import { INavItem } from './SidebarContent';

export interface IMenuItem {
  item: INavItem;
  active: boolean;
  open?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const MenuItem = ({ item, active }: IMenuItem) => (
  <Link
    id={`nav-${item?.name?.toLocaleLowerCase().split(' ').join('-')}`}
    key={item?.name}
    to={item?.href || '/'}
    className={`menu-item ${!item.icon && 'menu-sub-item'} ${active && item.icon && 'menu-item-active'} ${
      active && !item.icon && 'menu-sub-item-active'
    }`}
  >
    {item.icon && (
      <item.icon className={`mr-3 flex-shrink-0 ${active && 'text-sky-800 dark:text-slate-900'}`} aria-hidden="true" />
    )}
    {item?.name}
  </Link>
);
