import { GRLocalSystemNumber, IHttpSearchResponse, ISearchLocalSystemNumbersRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useLocalSystemNumbers = (request?: ISearchLocalSystemNumbersRequest): UseAxiosResult<IHttpSearchResponse<GRLocalSystemNumber>> => {
  return useAxios<IHttpSearchResponse<GRLocalSystemNumber>>({
    url: `/tcr-campaigns/${request?.tcrCampaignId}/local-system-numbers/search`,
    method: 'POST',
    data: request ?? {}
  }, {
    manual: true
  });
};

export default useLocalSystemNumbers;
