import { SocketName, SocketPayload } from "@shared/models";
import ReconnectingWebSocket from 'reconnecting-websocket';
import { getTokenResponse } from '../authAxios';

export type SocketSubscription<T> = (payload: SocketPayload<T>) => void;
type SubscriptionMap<T> = Map<SocketName, SocketSubscription<T>[]>;
type UnsubscribeCallback = () => void;

export class SocketManager {
  private socket: ReconnectingWebSocket;
  public subscriptions: SubscriptionMap<any> = new Map();

  constructor(socket: ReconnectingWebSocket) {
    this.socket = socket;
  }

  public subscribe<T>(name: SocketName, subscription: SocketSubscription<T>): UnsubscribeCallback {
    const subscriptions = this.subscriptions.get(name) ?? [];

    this.subscriptions.set(name, [...subscriptions, subscription]);

    const index = (this.subscriptions.get(name) ?? []).length - 1;

    return () => this.unsubscribe(name, index);
  };

  public sendMessage<T>(name: SocketName, data: T) {
    const access_token = getTokenResponse()?.access_token ?? null;
    const payload: SocketPayload<T> = { name, data, access_token };
    console.log('Sending Socket Payload', payload);
    this.socket.send(JSON.stringify(payload));
  };

  private unsubscribe(name: SocketName, index: number) {
    const subscriptions = this.subscriptions.get(name) ?? [];

    subscriptions.splice(index, 1);

    this.subscriptions.set(name, subscriptions);
  }
}
