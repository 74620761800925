import { Button, ButtonVariantEnum, LoadingIndicator, TextInput, ToggleSwitch, ToggleSwitchLabelLocationEnum } from '@Wonder-Cave/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { SQSQueueNameEnum } from '@shared/enums';
import { ICreateOptoutRequest, IHttpResponse, IOptoutsUploadRequest, IOptoutsUploadToSQSRequest, ISQSSendRequest } from '@shared/models';
import { uuid } from '@shared/services';
import { User } from 'auth0';
import axios from 'axios';
import { FastField, Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { axiosPost } from '../../authAxios';
import { INotification, NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useTenants from '../../hooks/useTenants';
import validateCsvHeaders from '../../utils/validateCsvHeaders';
import ClientDropdown from '../Clients/Dropdowns/ClientDropdown';
import { IDropdownValue } from '../shared/Form/Dropdown';
import GenericDropdown from '../shared/Form/Dropdowns/GenericDropdown';
import { FileUpload } from '../shared/Form/FileUpload';

export interface IUploadOptOutsForm {
  optoutsFile?: File;
  tenantId?: string;
  phoneNumber?: string;
  clientId?: string;
  systemOptOut: boolean;
}

const initialFormState: IUploadOptOutsForm = {
  optoutsFile: undefined,
  tenantId: undefined,
  phoneNumber: undefined,
  clientId: undefined,
  systemOptOut: false
};

const processOptoutUpload = async (formData: IUploadOptOutsForm, user: User | undefined, addNotification: (notification: INotification) => void, tenantOptions: IDropdownValue[]) => {
  if (!user?.email) {
    throw new Error('User email needs to be set');
  }

  const tenantsForOptoutUpload = formData.systemOptOut ? tenantOptions : [tenantOptions.find(t => t.value === formData.tenantId)];

  // if system optout, automatically set all-clients client id as as forms clientId value
  formData.clientId = formData.systemOptOut ? '11111111-1111-1111-1111-111111111111' : formData.clientId;
  console.log('tenants optout data will be uploaded to', tenantsForOptoutUpload);
  if (formData.optoutsFile && tenantsForOptoutUpload.length > 0) {
    await Promise.all(tenantsForOptoutUpload.map(async (tenantInfo) => uploadFile(formData, user?.email!, addNotification, tenantInfo!)));
  }

  if (formData.phoneNumber && tenantsForOptoutUpload.length > 0) {
    await Promise.all(tenantsForOptoutUpload.map(async (tenantInfo) => uploadPhoneNumber(formData, user?.email!, addNotification, tenantInfo!)));
  }
};

async function uploadPhoneNumber(formData: IUploadOptOutsForm, email: string, addNotification: Function, tenantInfo: IDropdownValue) {
  const optoutRequest: ICreateOptoutRequest = {
    orgId: tenantInfo?.additionalData?.orgId,
    clientId: formData.clientId ?? '',
    phoneNumber: formData.phoneNumber!,
    environment: tenantInfo?.additionalData?.environment
  };
  try {
    const response = await axiosPost<IHttpResponse<any>>('/optouts', optoutRequest);

    console.log(response);

    addNotification({
      header: 'Opt-out number uploaded successfully!',
      content: `Successfully uploaded the opt-out number for tenant ${tenantInfo?.label}`,
    });
  } catch (e: any) {
    let optoutError = 'An unexpected error has occured when attempting to opt out this number.';
    if (e.response?.data?.message)
      optoutError = e.response?.data?.message;

    addNotification({
      header: `${tenantInfo?.label} opt-out number failed`,
      content: `Error: ${optoutError}`,
      type: NotificationType.FAILURE
    });
  }
}

async function uploadFile(
  formData: IUploadOptOutsForm,
  email: string,
  addNotification: Function,
  tenantInfo: IDropdownValue
): Promise<void> {

  if (!tenantInfo) {
    addNotification({
      header: 'Missing Tenant Information',
      content: `Unable to upload opt-out file as tenant must be selected.`,
      type: NotificationType.FAILURE
    });
  }

  const request: IOptoutsUploadRequest = {
    clientId: formData?.clientId ?? '',
    email,
    fileName: formData?.optoutsFile?.name ?? '',
    environment: tenantInfo?.additionalData?.environment,
    orgId: tenantInfo?.additionalData.orgId,
    type: 'optouts'
  };
  // Grab s3 signed url
  try {
    const response = await axiosPost<IHttpResponse<string>>('/optouts/get-s3-url', request);

    console.log('response from get-s3-url request', response);
    // Upload file using default axios to avoid auth token

    await axios.put(response.data, formData.optoutsFile, {
      headers: { 'Content-Type': 'text/csv' },
    });
    const s3Url = response?.data ?? '';
    const fileKey = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/.exec(s3Url);
    if (fileKey) {
      const triggerPayload: ISQSSendRequest<IOptoutsUploadToSQSRequest> = {
        messageId: uuid(),
        queueName: SQSQueueNameEnum.OPTOUTS_UPLOAD,
        orgId: request.orgId,
        environment: request.environment,
        payload: {
          clientId: request.clientId,
          fileKey: fileKey[0],
          fileName: request.fileName,
          userEmail: request.email,
        },
      };

      await axiosPost<IHttpResponse<string>>('/optouts/send-sqs-message', triggerPayload);

      addNotification({
        header: `${tenantInfo?.label} Opt-out list upload successfully!`,
        content: `You will receive an email once the opt-outs have been added to the system`,
      });

    }
  } catch (e) {
    console.log(e);
    addNotification({
      header: `Opt-out list error`,
      content: `Unable to upload opt-out list file for tenant ${tenantInfo?.label}.`,
      type: NotificationType.FAILURE
    });
  }


}

const UploadOptOuts = () => {
  const [uploadingOptouts, setUploadingOptouts] = useState<boolean>(false);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const { user } = useAuth0();

  const handleSubmit = async (form: IUploadOptOutsForm) => {
    console.log('form', form);
    try {
      setUploadingOptouts(true);
      await processOptoutUpload(form, user, addNotification, tenantOptions);

      navigate('/opt-outs');

    } catch (e) {
      console.log(e);
    } finally {
      setUploadingOptouts(false);
    }
  };
  const schema: yup.SchemaOf<IUploadOptOutsForm> = yup.object().shape({
    optoutsFile: yup
      .mixed()
      .notRequired()
      .test('csvValidate', 'Uploaded file must be .csv', (file: File) => !file || file?.name?.endsWith('.csv'))
      .test(
        'csvHeaderValidate',
        'CSV does not contain the proper headers',
        async (file: File) => !file || await validateCsvHeaders(file, ['phone'])
      ),
    tenantId: yup.string().when('systemOptOut', {
      is: false,
      then: yup.string().required('Client is required when it is not a system opt out.')
    }),
    phoneNumber: yup.string().test('phoneNumber', 'Number must be 10 digits long', (num) => (!num || num === '0') || /^\d{10}$/.test(num)).notRequired(),
    clientId: yup.string().when('systemOptOut', {
      is: false,
      then: yup.string().required('Client is required when it is not a system opt out.')
    }),
    systemOptOut: yup.boolean().default(false)
  });

  const [{ data: tenants, loading: tenantsLoading }] = useTenants();
  const tenantOptions: IDropdownValue[] = tenants?.map(t => ({ label: `${t.name} (${t.environment.id})`, value: t.id, additionalData: { environment: t.environment.name, orgId: t.orgId } })) ?? [];


  return (
    <div className="pb-8 mt-12 px-28">

      <Formik
        initialValues={initialFormState}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form id="optouts-form" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mr-8 space-y-8 info-container h-full">
              <h2 className='text-black basis-1/2'>Create Opt-outs List</h2><div></div>
              <GenericDropdown
                label="Tenant"
                placeholder="Select a tenant"
                options={tenantOptions}
                value={tenantOptions.find(t => t.value === values.tenantId)}
                disabled={values.systemOptOut}
                onChange={(newValue) => {
                  setFieldValue('tenantId', newValue?.value);
                  setFieldTouched('tenantId');
                }}
                onBlur={() => {
                  setFieldTouched('tenantId');
                }}
                showError={!!(touched.tenantId && errors.tenantId)}
                errorMessage={(errors?.tenantId as any)?.value as string}
              />
              <div>
                <ClientDropdown
                  value={values.clientId ?? ''}
                  onChange={(newValue) => {
                    setFieldValue('clientId', newValue?.value);
                    setFieldTouched('clientId');
                  }}
                  onBlur={() => {
                    setFieldTouched('clientId');
                  }}
                  showError={!!(touched.clientId && errors.clientId)}
                  disabled={!tenantOptions.find(t => t.value === values.tenantId)?.additionalData || values.systemOptOut}
                  environment={tenantOptions.find(t => t.value === values.tenantId)?.additionalData?.environment}
                  orgId={tenantOptions.find(t => t.value === values.tenantId)?.additionalData?.orgId}
                />
                <ToggleSwitch
                  labelLocation={ToggleSwitchLabelLocationEnum.RIGHT}
                  label='System Opt-out'
                  labelStyle='text-bold' checked={values.systemOptOut} checkedColor='bg-turquoise' onChange={() => {
                    if (!values.systemOptOut) {
                      setFieldValue('tenantId', undefined);
                      setFieldValue('clientId', undefined);
                    }

                    setFieldValue('systemOptOut', !values.systemOptOut);
                  }} />
                <div>Opt-out this number across all tenants and clients in Grassroots.</div>
              </div>
              <FastField
                component={TextInput}
                id="phoneNumber"
                name="phoneNumber"
                label=".OPT-OUT NUMBER"
                value={values.phoneNumber}
                error={errors.phoneNumber}
                showError={!!(touched.phoneNumber && errors.phoneNumber)}
                onChange={(e) => {
                  const formattedValue = e.target.value.replace(/\D/g, '');
                  setFieldValue('phoneNumber', formattedValue);
                  setFieldTouched('phoneNumber');

                  if (values.optoutsFile) setFieldValue('optoutsFile', undefined);
                }}
                onBlur={() => { setFieldTouched('phoneNumber'); }}
              />

              <div>
                <FileUpload
                  id="optout-upload-file-selector"
                  name="optoutsFile"
                  label="Optouts Upload"
                  placeholder="Upload a .csv file"
                  value={values.optoutsFile as any}
                  error={touched.optoutsFile && (errors.optoutsFile as any)}
                  onBlur={handleBlur}
                  onChange={(event: any) => {
                    const file = event.target.files[0];

                    setFieldValue('optoutsFile', file);
                    setFieldTouched('optouts');

                    if (values.phoneNumber) setFieldValue('phoneNumber', undefined);
                  }}
                />
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Download the template{' '}
                    <Link
                      className="font-semibold text-sky-500 hover:underline"
                      to="/sample_upload_optouts.csv"
                      target="_blank"
                      download
                    >
                      here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className='float-right space-x-2 mt-[30%]'>
                <Button id="back" className="ml-auto" type="button" variant={ButtonVariantEnum.SECONDARY} onClick={() => navigate('/opt-outs')}>
                  Back
                </Button>
                <Button id="optouts-form" className="ml-auto" type="submit" variant={ButtonVariantEnum.PRIMARY} disabled={(!values.systemOptOut && (!values.tenantId || !values.clientId)) || ((!values.phoneNumber || values.phoneNumber === '0') && !values.optoutsFile)}>
                  {uploadingOptouts ? <LoadingIndicator position="CENTER" /> : 'Upload'}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UploadOptOuts;;