import { getCsvHeaders } from '../providers/validation.provider';

async function validateCsvHeaders(file: File, validHeaders: string[]) {
  try {

    const fileHeaders = (await getCsvHeaders(file)).map((header) => header.toLowerCase());

    return (
      validHeaders.length === fileHeaders.length &&
      fileHeaders.every((header, index) => fileHeaders[index] === validHeaders[index])
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default validateCsvHeaders;