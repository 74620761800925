import { GREnvironment, IHttpSearchResponse, ISearchClientsRecord } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

interface IClientsRequest {
  environment: GREnvironment;
  name?: string;
}

export default function useClientsManual({ environment, name }: IClientsRequest): UseAxiosResult<IHttpSearchResponse<ISearchClientsRecord>> {
  return useAxios<IHttpSearchResponse<ISearchClientsRecord>>({
    url: 'clients',
    method: 'GET',
    params: {
      environment
    },
  }, {
    manual: true
  });
}