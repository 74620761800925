import { GRClientProvider, IHttpResponse } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useProvidersForClient(environment?: string, clientId?: string): UseAxiosResult<IHttpResponse<GRClientProvider[]>> {
  return useAxios<IHttpResponse<GRClientProvider[]>>({
    url: `clients/${clientId}/providers-get-for-client?environment=${environment}`,
    method: 'GET'
  }, {
    manual: true
  });
}
