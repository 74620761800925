import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useProviders from 'apps/gsd-portal/src/hooks/useProviders';

interface IProviderDropdownProps {
  value: string;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  tollFree?: boolean;
}

const ProviderDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  tollFree,
}: IProviderDropdownProps): JSX.Element => {
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();

  const providers: IDropdownValue[] = (
    providerData?.map((provider) => {
      return {
        label: provider.name ?? '',
        value: provider.id ?? '',
      };
    }) ?? []
  ).filter((p) => (tollFree ? !p.label.includes('10DLC') : p));

  return (
    <Autocomplete
      label="Provider"
      value={providers.find((provider) => provider.value === value)}
      options={providers}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default ProviderDropdown;
