export const isFileMediaType = (file: File): boolean => {
  return file ? file.type.startsWith('image') || file.type.startsWith('video') : false;
};

export const isFileTollFreeSize = (file: File): boolean => {
  return file ? Math.ceil(file.size / 1024) <= 524 : false;
};

export const isFileLocalSize = (file: File): boolean => {
  return file ? Math.ceil(file.size / 1024) <= 1024 : false;
};

export const getCsvHeaders = (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    if (file && file.name.endsWith('.csv')) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const buffer = event?.target?.result;

        const encoder = new TextDecoder('utf-8');

        const csvContent = encoder.decode(buffer as ArrayBuffer);

        const headers = csvContent
          .split('\n')[0]
          .split(',')
          .map((header) => {
            return header.replace(/[\r\n]/g, '');
          });

        resolve(headers);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Error when attempting to parse the file'));
      };

      reader.readAsArrayBuffer(file.slice(0, 64));
    }
  });
};
