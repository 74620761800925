import react from 'react';

const Festive = (): JSX.Element => {
  const fireworks: JSX.Element = (
    <div className="pyro">
      <div className="before"></div>
      <div className="after"></div>
    </div>
  );

  const snow: JSX.Element = (
    <>
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
      <div className="snow" />
    </>
  );

  const holidayMap = new Map<number, JSX.Element>();

  holidayMap.set(1, fireworks);
  holidayMap.set(6, fireworks);
  holidayMap.set(12, snow);

  const getFestiveComponent = () => {
    //  1: New Years
    //  2: Valentines
    //  3: St Patricks
    //  4: Easter
    //  5: Memorial Day
    //  6: Juneteenth
    //  7: 4th of July
    //  8: Aug
    //  9: Labor Day
    //  10: Halloween
    //  11: Thanksgiving
    //  12: Christmas

    return holidayMap.get(new Date().getMonth() + 1);
  };

  return <>{getFestiveComponent()}</>;
};

export default Festive;
