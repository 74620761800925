import Shimmer, { ShimmerType } from './Shimmer';

const AuthorizedShimmer = (): JSX.Element | null => {
  return (
    <div className="flex flex-col space-y-4">
      <Shimmer type={ShimmerType.HEADER} />
      <Shimmer type={ShimmerType.TABLE} columns={5} />
    </div>
  );
};

export default AuthorizedShimmer;
