import { HttpRequestSchema } from '@shared/services';
import { object, string } from 'yup';

export interface IProviderGetOneRequest {
  id: string;
}

export const IProviderGetOneRequestSchema = object<HttpRequestSchema<IProviderGetOneRequest>>({
  id: string().required(),
});
