import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';

interface IRegionDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue: IDropdownValue | undefined) => void;
  options: IDropdownValue[];
  refreshOptions: (searchvalue: string) => Promise<void>;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  label?: string;
  loading?: boolean;
  errorLocation?: 'BOTTOM';
}

const RegionDropdown = ({
  value,
  options,
  onChange,
  onBlur,
  refreshOptions,
  disabled,
  errorMessage,
  showError,
  label,
  loading,
  errorLocation,
}: IRegionDropdownProps): JSX.Element => {
  return (
    <Autocomplete
      label={label}
      placeholder="Search for a City"
      value={value}
      options={options}
      refreshOptions={refreshOptions}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      loading={loading}
      errorLocation={errorLocation}
    />
  );
};

export default RegionDropdown;
