import { Button, ButtonVariantEnum, Chip } from '@Wonder-Cave/ui';
import { ISearchClientsRecord, IUpdateClientRequest, IUpdateClientResponse } from '@shared/models';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPut, getQueryParamString } from '../../authAxios';
import TCRCampaigns from '../TCRCampaigns/TCRCampaigns';
import { BrandInformation } from './Tabs/BrandInformation';
import { ClientInformation } from './Tabs/ClientInformation';

export enum ClientDetailsViewEnum {
  CLIENT_INFO = 0,
  BRAND_INFO = 1,
  TCR_CAMPAIGNS = 2
}

export interface ClientDetailsProps {
  view?: ClientDetailsViewEnum;
}

const ClientDetails = ({ view: viewProp }: ClientDetailsProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { clientId } = useParams();
  const [client, setClient] = useState<ISearchClientsRecord>();
  const [view, setView] = useState(viewProp ?? ClientDetailsViewEnum.CLIENT_INFO);

  const getClient = async () => {
    setLoading(true);
    try {
      const response = await axiosGet<ISearchClientsRecord>(`/clients/${clientId}?${getQueryParamString({ environment: location?.state?.environment })}`);
      if (response.status === 200) {
        setClient(response?.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateClient = async (request: Omit<IUpdateClientRequest, 'id'>) => {
    await axiosPut<IUpdateClientResponse>(`/clients/${clientId}`, request);
  };

  const setViewComponent = (view: ClientDetailsViewEnum) => {
    switch (view) {
      case ClientDetailsViewEnum.CLIENT_INFO:
        navigate(`/clients/${clientId}/info`);
        setView(view);
        break;
      case ClientDetailsViewEnum.BRAND_INFO:
        navigate(`/clients/${clientId}/brand`);
        setView(view);
        break;
      case ClientDetailsViewEnum.TCR_CAMPAIGNS:
        navigate(`/clients/${clientId}/tcr`);
        setView(view);
        break;
    }
  };

  const ViewComponent = ({ view }: ClientDetailsProps) => {
    switch (view) {
      case ClientDetailsViewEnum.CLIENT_INFO:
        return <ClientInformation client={client} loading={loading} />;
      case ClientDetailsViewEnum.BRAND_INFO:
        return <BrandInformation client={client} />;
      case ClientDetailsViewEnum.TCR_CAMPAIGNS:
        return <TCRCampaigns client={client} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (clientId) {
      getClient();
    }
  }, []);

  return <div>
    <div className='px-20'>
      <div className='flex justify-between'>
        <h1 className='mb-2'>{client?.name}</h1>
        <Button onClick={() => navigate('/clients')} variant={ButtonVariantEnum.SECONDARY}>BACK</Button>
      </div>
      <div className='flex mb-8 text-wc-blue'><h3 className='mr-2'>{'Brand ID: ' + client?.tcrBrandId}</h3> | <h3 className='ml-2'>{'Universal EIN: ' + client?.tcrBrand?.universalEin}</h3></div>
    </div>
    <div className="flex items-center px-20 mb-8 space-x-4">
      <h4 className="text-dark-gray">View:</h4>
      <Chip active={view === ClientDetailsViewEnum.CLIENT_INFO} color='bg-turquoise' onClick={() => setViewComponent(ClientDetailsViewEnum.CLIENT_INFO)}>
        Client Info
      </Chip>
      <Chip active={view === ClientDetailsViewEnum.BRAND_INFO} color='bg-turquoise' onClick={() => setViewComponent(ClientDetailsViewEnum.BRAND_INFO)}>
        Brand Info
      </Chip>
      <Chip active={view === ClientDetailsViewEnum.TCR_CAMPAIGNS} color='bg-turquoise' onClick={() => setViewComponent(ClientDetailsViewEnum.TCR_CAMPAIGNS)}>
        TCR Campaigns
      </Chip>
    </div>
    <ViewComponent view={view} />
  </div>;
};

export default ClientDetails;
