export enum TcrEntityType {
  PRIVATE_PROFIT = 'PRIVATE_PROFIT',
  PUBLIC_PROFIT = 'PUBLIC_PROFIT',
  NON_PROFIT = 'NON_PROFIT',
  GOVERNMENT = 'GOVERNMENT',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR'
}

export const clientTcrEntityTypeDisplays = new Map<TcrEntityType, string>([
  [TcrEntityType.PRIVATE_PROFIT, 'Private company'],
  [TcrEntityType.PUBLIC_PROFIT, 'Publicly traded company'],
  [TcrEntityType.NON_PROFIT, 'Non-Profit organization'],
  [TcrEntityType.GOVERNMENT, 'Government corporation'],
  [TcrEntityType.SOLE_PROPRIETOR, 'Sole proprietorship']
]);
