import { TestConfig } from 'yup';
import { AnyObject } from 'yup/lib/types';
import { isEmail } from './utils.service';

export function isPhoneNumber(propName: string, label?: string): TestConfig<number | undefined, AnyObject> {
  return {
    name: `isPhoneNumber-${propName}`,
    test: num => !num || num.toString().length == 10,
    message: `${label ?? propName} must be a valid 10-digit phone number, i.e. 4105552456`
  };
}

export function isValidEmail(propName: string, label?: string): TestConfig<string | undefined, AnyObject> {
  return {
    name: `isValidEmail-${propName}`,
    test: email => !email || isEmail(email),
    message: `${label ?? propName} must be a valid email address`
  };
}
