import { Button, ButtonVariantEnum, Checkbox } from '@Wonder-Cave/ui';
import { SystemNumberStatusEnum } from '@shared/enums/system-number-status-enum';
import { ISearchClientsRecord, ISearchTcrCampaignsRecord } from '@shared/models';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deFormatPhone } from '../../../../gsd-shared/services/utils/utils.service';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { IDropdownValue } from '../shared/Form/Dropdown';
import SystemNumbersDropdown from '../shared/Form/Dropdowns/SystemNumbersDropdown';
import CustomShimmer from '../shared/Other/CustomShimmer';
import { IDeactivateNumbersForm, deactivateNumbersSchema, deactivateSystemNumbers, formatPhoneNumber } from './types';

export interface DeactivateNumbersProps {
  isReadOnly?: boolean;
  client?: ISearchClientsRecord;
  tcrCampaign?: ISearchTcrCampaignsRecord;
  isLoading: boolean;
  isActiveSystemNumbersLoading?: boolean;
  activeSystemNumbers?: { number: string; status: SystemNumberStatusEnum; }[] | undefined;
  onDeactivate: (numbers: string[]) => Promise<void>;
}

const DeactivateNumbers = ({
  isReadOnly,
  client,
  tcrCampaign,
  isLoading,
  isActiveSystemNumbersLoading,
  activeSystemNumbers,
  onDeactivate,
}: DeactivateNumbersProps) => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const getOptions = (numbers: { number: string; status: SystemNumberStatusEnum; }[] | undefined): IDropdownValue[] => {
    return numbers
      ?.filter(activeNumber => !!activeNumber)
      ?.map(activeNumber => ({
        label: `${formatPhoneNumber(activeNumber.number)} (${activeNumber.status})`, // todo 3418: include status for label
        value: activeNumber
      })) ?? [];
  };

  const initialFormState: IDeactivateNumbersForm = {
    environment: tcrCampaign?.environment!,
    tcrCampaignId: tcrCampaign?.id!,
    phoneNumbers: []
  };

  const [selectedNumbers, setSelectedNumbers] = useState([] as IDropdownValue[]);
  const [selectedAllPhoneNumbers, setSelectedAllPhoneNumbers] = useState<boolean>(false);
  const systemNumberOptions: IDropdownValue[] = getOptions(activeSystemNumbers);
  const [isDeactivateNumbersLoading, setIsDeactivateNumbersLoading] = useState(false);

  const isFullDisable = !(isReadOnly || isDeactivateNumbersLoading || (selectedNumbers?.length ?? 0) <= 0 || !tcrCampaign?.isActive);
  const allSelected = (activeSystemNumbers?.length ?? 0) > 0 && (selectedNumbers?.length ?? 0) === (activeSystemNumbers?.length ?? 0);
  const showShimmer = !!isLoading || !!isActiveSystemNumbersLoading;

  const deactivatePhoneNumbers = async (formData: IDeactivateNumbersForm) => {
    try {
      setIsDeactivateNumbersLoading(true);

      await deactivateSystemNumbers(formData);
      setSelectedNumbers([]);
      await onDeactivate(formData?.phoneNumbers ?? []);

      addNotification({
        header: 'System Numbers deactivated successfully!',
      });

    } catch (error: any) {
      console.error(error);

      if (error?.constructor?.name === 'Cancel') {
        return;
      }

      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to deactivate System Numbers.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsDeactivateNumbersLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAllPhoneNumbers) {
      setSelectedNumbers(systemNumberOptions);
    }
  }, [selectedAllPhoneNumbers]);

  useEffect(() => {
    if (allSelected !== selectedAllPhoneNumbers) {
      setSelectedAllPhoneNumbers(allSelected);
    }
  }, [selectedNumbers]);

  return <Formik
    initialValues={initialFormState!}
    enableReinitialize
    validationSchema={deactivateNumbersSchema}
    validateOnBlur
    onSubmit={(formData: IDeactivateNumbersForm) => deactivatePhoneNumbers(formData)}
  >
    {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty }) => (
      <div className='flex flex-col w-full'>
        <form id="phone-number-deactivate" onSubmit={handleSubmit} className='flex flex-col w-full'>
          <h1>
            Deactivate Numbers
          </h1>

          <h3 className='pb-8 text-medium-gray'>
            Select numbers to deactivate
          </h3>

          <div className='flex flex-col pb-8'>
            <div className='flex flex-row justify-between pb-2 select-none'>
              <h5 className='text-medium-gray'>NUMBERS</h5>

              <Checkbox
                id='Select All'
                name='Select All'
                label='Select All'
                checked={selectedAllPhoneNumbers}
                disabled={isReadOnly || showShimmer || isDeactivateNumbersLoading || !tcrCampaign?.isActive}
                onChange={(value) => {
                  const shouldSelectAll = !selectedAllPhoneNumbers;
                  const shouldDeselectAll = !shouldSelectAll && allSelected;

                  setSelectedAllPhoneNumbers(shouldSelectAll);

                  if (shouldSelectAll) {
                    setFieldTouched('phoneNumbers');
                    setFieldValue('phoneNumbers', activeSystemNumbers?.map(number => deFormatPhone(number.number))?.filter(v => !!v) ?? []);
                  }

                  if (shouldDeselectAll) {
                    setFieldTouched('phoneNumbers');
                    setFieldValue('phoneNumbers', []);
                    setSelectedNumbers([]);
                  }
                }}
              />
            </div>

            <CustomShimmer isVisible={showShimmer} className='h-10' element={
              <SystemNumbersDropdown
                value={selectedNumbers}
                onChange={(newValue) => {
                  setFieldTouched('phoneNumbers');
                  setFieldValue('phoneNumbers', newValue?.map(option => deFormatPhone(option?.value.number))?.filter(v => !!v) ?? []);

                  setSelectedNumbers(newValue as IDropdownValue[]);
                }}
                onBlur={() => {
                  setFieldTouched('phoneNumbers');
                }}
                options={systemNumberOptions}
                disabled={isReadOnly || isDeactivateNumbersLoading || isActiveSystemNumbersLoading || !tcrCampaign?.isActive}
                showError={!!(touched.phoneNumbers && errors.phoneNumbers)}
                errorMessage={touched?.phoneNumbers ? errors?.phoneNumbers?.toString() : ''}
                errorLocation={'BOTTOM'}
                optionLabelClassName={'select-none'}
              />
            } />

            <div className='flex flex-row pt-8'>
              <div className='flex flex-row justify-between items-center'>
                <Button
                  type="submit"
                  isLoading={isDeactivateNumbersLoading}
                  disabled={!isFullDisable}
                  variant={ButtonVariantEnum.SECONDARY}
                  className={`${isFullDisable ? 'cursor-pointer' : 'cursor-auto'}`}>
                  DEACTIVATE NUMBERS
                </Button>
              </div>
            </div>
          </div>
        </form>

        <div className="flex flex-row justify-end w-full mt-8">
          <Button variant={ButtonVariantEnum.SECONDARY} className="mr-4" onClick={() => navigate(-1)}>BACK</Button>
        </div>
      </div>
    )}
  </Formik >;
};

export default DeactivateNumbers;

