import { Button, ButtonVariantEnum, LoadingIndicator, TextInput } from '@Wonder-Cave/ui';
import { User, useAuth0 } from '@auth0/auth0-react';
import { ICreateOptoutRequest, IHttpResponse, IOptoutsUploadRequest } from '@shared/models';
import axios from 'axios';
import { FastField, Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { axiosPost } from '../../authAxios';
import { INotification, NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useTenants from '../../hooks/useTenants';
import validateCsvHeaders from '../../utils/validateCsvHeaders';
import ClientDropdown from '../Clients/Dropdowns/ClientDropdown';
import { IDropdownValue } from '../shared/Form/Dropdown';
import GenericDropdown from '../shared/Form/Dropdowns/GenericDropdown';
import { FileUpload } from '../shared/Form/FileUpload';

export interface IUploadOptInsForm {
  optInsFile?: File;
  tenantId?: string;
  phoneNumber?: string;
  clientId: string;
}

const initialFormState: IUploadOptInsForm = {
  optInsFile: undefined,
  tenantId: undefined,
  phoneNumber: undefined,
  clientId: ''
};
interface IUploadOptInsProps {
  loading: boolean;
}

const processOptinUpload = async (formData: IUploadOptInsForm, user: User | undefined, addNotification: (notification: INotification) => void, tenantOptions: IDropdownValue[]) => {
  if (formData.optInsFile) {
    if (!user?.email) {
      throw new Error('User email needs to be set');
    }
    await uploadFile(formData, user?.email, addNotification, tenantOptions);
  }

  if (formData.phoneNumber) {
    const tenantInfo = tenantOptions.find(t => t.value === formData.tenantId);
    const optinRequest: ICreateOptoutRequest = {
      orgId: tenantInfo?.additionalData?.orgId,
      clientId: formData.clientId ?? '',
      phoneNumber: formData.phoneNumber,
      environment: tenantInfo?.additionalData?.environment
    };
    const response = await axiosPost<IHttpResponse<any>>('/optins', optinRequest);

    console.log(response);

    if (response.status === 200) {
      addNotification({
        header: 'Opt-in Number uploaded successfully!',
        content: `Successfully uploaded the opt-in number`,
      });
    }

  }
};

async function uploadFile(
  formData: IUploadOptInsForm,
  email: string,
  addNotification: Function,
  tenantOptions: IDropdownValue[]
): Promise<void> {

  const tenantInfo = tenantOptions.find(t => t.value === formData.tenantId);
  if (!tenantInfo) {
    addNotification({
      header: 'Missing Tenant Information',
      content: `Unable to upload opt-in file as tenant must be selected.`,
      type: NotificationType.FAILURE
    });
  }

  const request: IOptoutsUploadRequest = {
    clientId: formData?.clientId ?? '',
    email,
    fileName: formData?.optInsFile?.name ?? '',
    environment: tenantInfo?.additionalData?.environment,
    orgId: tenantInfo?.additionalData.orgId,
    type: 'optout-undo'
  };
  // Grab s3 signed url
  const response = await axiosPost<IHttpResponse<string>>('/optouts/get-s3-url', request);

  console.log('response from get-s3-url request', response);
  // Upload file using default axios to avoid auth token
  await axios.put(response.data, formData.optInsFile, {
    headers: { 'Content-Type': 'text/csv' },
  });

  addNotification({
    header: 'Opt-in list uploaded successfully!',
    content: `You will receive an email once the opt-ins have been added to the system.`,
  });
}

const UploadOptIns = () => {
  const [uploadingOptIns, setUploadingOptIns] = useState<boolean>(false);
  const { addNotification } = useNotifications();
  const { user } = useAuth0();

  const navigate = useNavigate();
  const handleSubmit = async (form: IUploadOptInsForm) => {
    try {
      setUploadingOptIns(true);


      await processOptinUpload(form, user, addNotification, tenantOptions);

      navigate('/opt-outs');

    } catch (e) {
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to upload opt-ins.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setUploadingOptIns(false);
    }
  };

  const schema: yup.SchemaOf<IUploadOptInsForm> = yup.object().shape({
    optInsFile: yup
      .mixed()
      .notRequired()
      .test('csvValidate', 'Uploaded file must be .csv', (file: File) => !file || file?.name?.endsWith('.csv'))
      .test(
        'csvHeaderValidate',
        'CSV does not contain the proper headers',
        async (file: File) => !file || await validateCsvHeaders(file, ['phone'])
      ),
    tenantId: yup.string().notRequired(),
    phoneNumber: yup.string().test('phoneNumber', 'Number must be 10 digits long', (num) => !num || /^\d{10}$/.test(num)).notRequired(),
    clientId: yup.string().required()
  }
  );

  const [{ data: tenants, loading: tenantsLoading }] = useTenants();
  const tenantOptions: IDropdownValue[] = tenants?.map(t => ({ label: `${t.name} (${t.environment.id})`, value: t.id, additionalData: { environment: t.environment.name, orgId: t.orgId } })) ?? [];


  return (
    <div className="pb-8 mt-12 px-28">

      <Formik
        initialValues={initialFormState}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form id="optins-form" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mr-8 space-y-8 info-container h-full">
              <h2 className='text-black basis-1/2'>Create Opt-Ins List</h2><div></div>
              <GenericDropdown
                label="Tenant"
                placeholder="Select a tenant"
                options={tenantOptions}
                value={tenantOptions.find(t => t.value === values.tenantId)}
                onChange={(newValue) => {
                  setFieldValue('tenantId', newValue?.value);
                  setFieldTouched('tenantId');
                }}
                onBlur={() => {
                  setFieldTouched('tenantId');
                }}
                showError={!!(touched.tenantId && errors.tenantId)}
                errorMessage={(errors?.tenantId as any)?.value as string}
              />
              <ClientDropdown
                value={values.clientId}
                onChange={(newValue) => {
                  setFieldValue('clientId', newValue?.value);
                  setFieldTouched('clientId');
                }}
                onBlur={() => {
                  setFieldTouched('clientId');
                }}
                showError={!!(touched.clientId && errors.clientId)}
                disabled={!tenantOptions.find(t => t.value === values.tenantId)?.additionalData}
                environment={tenantOptions.find(t => t.value === values.tenantId)?.additionalData?.environment}
                orgId={tenantOptions.find(t => t.value === values.tenantId)?.additionalData?.orgId}
              />
              <div>
                <FastField
                  component={TextInput}
                  id="phoneNumber"
                  name="phoneNumber"
                  label=".OPT-In NUMBER"
                  value={values.phoneNumber}
                  error={errors.phoneNumber}
                  showError={!!(touched.phoneNumber && errors.phoneNumber)}
                  onChange={(e) => {
                    const formattedValue = e.target.value.replace(/\D/g, '');
                    setFieldValue('phoneNumber', formattedValue);
                    setFieldTouched('phoneNumber');

                    if (values.optInsFile) setFieldValue('optInsFile', undefined);
                  }}
                  onBlur={() => { setFieldTouched('phoneNumber'); }}
                />
              </div>
              <div>
                <FileUpload
                  id="optIn-upload-file-selector"
                  name="optInsFile"
                  label="OptIns Upload"
                  placeholder="Upload a .csv file"
                  value={values.optInsFile as any}
                  error={touched.optInsFile && (errors.optInsFile as any)}
                  onBlur={handleBlur}
                  onChange={(event: any) => {
                    const file = event.target.files[0];

                    setFieldValue('optInsFile', file);
                    setFieldTouched('optIns');

                    if (values.phoneNumber) setFieldValue('phoneNumber', undefined);
                  }}
                />
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Download the template{' '}
                    <Link
                      className="font-semibold text-sky-500 hover:underline"
                      to="/sample_upload_optouts.csv"
                      target="_blank"
                      download
                    >
                      here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className='float-right space-x-2 mt-[30%]'>
                <Button id="back" className="ml-auto" type="button" variant={ButtonVariantEnum.SECONDARY} onClick={() => navigate('/opt-outs')}>
                  Back
                </Button>
                <Button id="optins-form" className="ml-auto" type="submit" variant={ButtonVariantEnum.PRIMARY} disabled={(!values.tenantId || !values.clientId) || (!values.phoneNumber && !values.optInsFile)}>
                  {uploadingOptIns ? <LoadingIndicator position="CENTER" /> : 'Upload'}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default UploadOptIns;