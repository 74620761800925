import { Button, ButtonVariantEnum, IColumnClickConfig, IColumnRenderConfig, TableList, ToggleSwitch, ToggleSwitchLabelLocationEnum } from '@Wonder-Cave/ui';
import { TcrCampaignStage } from '@shared/enums';
import { GRTcrCampaign, ISearchClientsRecord, ISearchRequest, IUpdateTcrCampaignRequest, IUpdateTcrCampaignResponse } from '@shared/models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosPut } from '../../authAxios';
import useTcrCampaigns from '../../hooks/useTcrCampaigns';
import { ITcrCampaignItem, defaultTcrCampaignTableOptions, getColumns, tcrCampaignTableTake } from './types';

interface TCRCampaignsProps {
  client?: ISearchClientsRecord;
}

const TCRCampaigns = ({ client }: TCRCampaignsProps) => {
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [searchText, setSearchText] = useState('');
  const [tableOptions, setTableOptions] = useState(defaultTcrCampaignTableOptions);

  const [{ data: tcrCampaignsSearchResponse, loading, error }, refetch] = useTcrCampaigns({ searchRequest: tableOptions, clientId });
  const [tcrCampaigns, setTcrCampaigns] = useState([] as ITcrCampaignItem[]);
  const tcrCampaignsTotalCount = tcrCampaignsSearchResponse?.totalCount ?? 0;

  useEffect(() => {
    setTcrCampaigns(tcrCampaignsSearchResponse?.records ?? []);
  }, [tcrCampaignsSearchResponse]);

  const columnClickConfig: IColumnClickConfig = {
    title: (item: ITcrCampaignItem) => navigate(`/clients/${clientId}/tcr/${item.id}`),
  };

  const columnRenderConfig: IColumnRenderConfig = {
    provider: (item: ITcrCampaignItem) => <span>
      {item?.provider?.name ?? 'N/A'}
    </span>,
    stage: (item: ITcrCampaignItem) => {
      switch (item?.stage) {
        case TcrCampaignStage.CAMPAIGN_ADD:
          return <span title="Campaign Add" className="select-none">
            <img id="time" className="w-auto h-22" src={`/TcrTime.svg`} />
          </span>;
        case TcrCampaignStage.CAMPAIGN_SHARE_ACCEPT:
          return <span title="Campaign Share Accept" className="select-none">
            <img id="time" className="w-auto h-22" src={`/TcrTime.svg`} />
          </span>;
        case TcrCampaignStage.CAMPAIGN_DCA_COMPLETE:
          return <span title="Campaign DCA Complete" className="select-none">
            <img id="tick" className="w-auto h-22" src={`/TcrTick.svg`} />
          </span>;
        case TcrCampaignStage.CAMPAIGN_SHARE_DELETE:
          return <span title="Campaign Share Deleted" className="select-none">
            <img id="delete" className="w-auto h-22" src={`/TcrDelete.svg`} />
          </span >;
        default:
          return <span title="Unknown" className="select-none">
            <img id="time" className="w-auto h-22" src={`/TcrTime.svg`} />
          </span>;
      }
    },
    isActive: (item: ITcrCampaignItem) =>
      <ToggleSwitch
        checked={!!item?.isActive}
        disabled={item?.isLoading ?? false}
        labelLocation={ToggleSwitchLabelLocationEnum.RIGHT}
        checkedColor={item?.isLoading ? 'bg-mystic' : 'bg-turquoise'}
        uncheckedColor={item?.isLoading ? 'bg-mystic' : 'bg-silver'}
        onChange={async () => {

          function toggle(tcr: ITcrCampaignItem) {
            setIsActive(tcr, !tcr.isActive);
          }

          function setIsActive(tcr: ITcrCampaignItem, value: boolean) {
            tcr.isActive = value;
            setTcrCampaigns([...tcrCampaigns]);
          }

          const tcr = tcrCampaigns.find(tcr => tcr.id === item?.id);
          if (tcr) {
            // toggle ui for immediate feedback
            tcr.isLoading = true;
            toggle(tcr);

            try {
              const request: IUpdateTcrCampaignRequest = {
                environment: tcr.environment,
                id: tcr.id,
                isActive: tcr.isActive,
                isFullDisable: false // don't disable real tcr from grid
              };

              await axiosPut<IUpdateTcrCampaignResponse>(`/tcr-campaigns/${tcr.id}`, request);
            } catch (error: any) {
              console.error('Error updating status:', error);

              const currentTcrCampaign: GRTcrCampaign = error?.response?.data?.tcrCampaign;

              currentTcrCampaign
                ? setIsActive(tcr, !!currentTcrCampaign?.isActive)
                : toggle(tcr);
            } finally {
              tcr.isLoading = false;
              setTcrCampaigns([...tcrCampaigns]);
            }
          }
        }}

      />,
  };

  const columns = getColumns(columnClickConfig, columnRenderConfig);

  const handlePagination = (page: number) => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.pagination.skip = page * tcrCampaignTableTake;
    newSearchOptions.pagination.take = tcrCampaignTableTake;

    handleSearchOptionChange(newSearchOptions);
  };

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  return <div>
    <div className='flex items-center justify-between px-28'>
      <h2>TCR Campaigns</h2>
      <Button disabled={!client} onClick={() => navigate(`/clients/${clientId}/tcr/add`, { state: { clientName: client?.name, brandVertical: client?.tcrBrand?.vertical } })} type={'button'} variant={ButtonVariantEnum.PRIMARY}>ADD CAMPAIGNS</Button>
    </div>

    <div className='pb-5'>
      <TableList
        loading={loading}
        tableHeight='max-h-[50vh]'
        shimmer
        columns={columns}
        items={tcrCampaigns}
        totalCount={tcrCampaignsTotalCount}
        limit={tcrCampaignTableTake}
        actions={[]}
        paginate
        onPaginate={handlePagination}
        cssOverride={{
          'table-list': `!px-0`,
          'table-list-header': `!pl-4`
        }}
      />
    </div>
  </div>;
};

export default TCRCampaigns;
